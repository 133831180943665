import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { ChatView } from 'src/sections/chat/view';

// ----------------------------------------------------------------------

export default function ChatPage() {
  return (
    <>
      <MetaHelmet title="Chats" />
      <ChatView />
    </>
  );
}
