import { useMemo } from 'react';

import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
// ----------------------------------------------------------------------
import PermContactCalendarRoundedIcon from '@mui/icons-material/PermContactCalendarRounded';

import { paths } from 'src/routes/routes/paths';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify/iconify';

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

// ----------------------------------------------------------------------

export function useNavData() {
  const { role } = useGetRoles();

  const memberSideBarData = useMemo(
    () => [
      {
        subheader: 'Overview',
        items: [
          { title: 'Summery', path: `${paths.dashboard.root}`, icon: <DashboardRoundedIcon /> },
          { title: 'Contact', path: `${paths.dashboard.contact}`, icon: <PermContactCalendarRoundedIcon /> },
          { title: 'Chat', path: `${paths.dashboard.chat}`, icon: icon('ic_chat'), },
        ],
      },
    ],
    []
  );

  const adminSideBarData = useMemo(() => [
    {
      subheader: 'Dashboard',
      items: [
        { title: 'Dashboard', path: `${paths.dashboard.root}`, icon: <DashboardRoundedIcon /> },
        {
          title: 'Members',
          path: paths.dashboard.member,
          icon: icon('ic_user'),
        },

        {
          title: 'Settings',
          path: paths.dashboard.settings,
          icon: <Iconify icon="solar:settings-bold-duotone" width={24} />,
        },
      ],
    },
  ], []);

  const data = useMemo(() => {
    const handleSideBarDataWithRole = () => {
      switch (role) {
        case 'admin':
          return adminSideBarData;
        case 'member':
          return memberSideBarData;
        default:
          return [];
      }
    };

    return handleSideBarDataWithRole();
  }, [adminSideBarData, memberSideBarData, role]);

  return data;
}

// const ICONS = {
//   job: icon('ic_job'),
//   blog: icon('ic_blog'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   tour: icon('ic_tour'),
//   order: icon('ic_order'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   product: icon('ic_product'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
// };
