
import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import ForgotPassword from 'src/sections/auth/modern-forgot-password-view';

export default function ForgotPasswordPage() {
  return (
    <>
      <MetaHelmet title=" Forgot Password" />

      <ForgotPassword />
    </>
  );
}
