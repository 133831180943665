import React, { useState, useEffect } from 'react';

import { Button } from '@mui/material';

const FacebookLoginButton = () => {
  const [profile, setProfile] = useState('');

  useEffect(() => {
    // Load the Facebook SDK
    ((d, s, id) => {
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      const fjs = d.getElementsByTagName(s)[0];
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    window.fbAsyncInit = () => {
      if (window.FB) {
        window.FB.init({
          appId: '473396662019101',
          cookie: true,
          xfbml: true,
          version: 'v19.0',
        });
      }
    };
  }, []);

  const launchWhatsAppSignup = () => {
    if (window.FB) {
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            console.log('Welcome! Fetching your information....');
            window.FB.api('/me', { fields: 'name, email' }, (finalResponse) => {
              setProfile(
                `Good to see you, ${finalResponse.name}. I see your email address is ${finalResponse.email}`
              );
            });
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        },
        {
          config_id: '695552089364339',
          response_type: 'code',
          override_default_response_type: true,
        }
      );
    }
  };

  console.log(profile);

  return (
    <>
      <Button variant="contained" color="primary" onClick={launchWhatsAppSignup}>
        Connect your account with Facebook
      </Button>
      {/* <p id="profile">{profile}</p> */}
    </>
  );
};

export default FacebookLoginButton;
