import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputBase from '@mui/material/InputBase';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import { Grow, Stack, CircularProgress } from '@mui/material';

import { createChatMessage } from 'src/server/api/chat';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function ChatMessageInput({
  disabled,
  selectedConversationId,
}) {
  const dispatch = useDispatch()
  // const fileRef = useRef(null);

  const [message, setMessage] = useState('');

  const { createMsgLoading } = useSelector((state) => ({
    createMsgLoading: state.chat.createMsgLoading,
  }));

  // const myContact = useMemo(
  //   () => ({
  //     id: `${user?.id}`,
  //     role: `${user?.role}`,
  //     email: `${user?.email}`,
  //     address: `${user?.address}`,
  //     name: `${user?.displayName}`,
  //     lastActivity: new Date(),
  //     avatarUrl: `${user?.photoURL}`,
  //     phoneNumber: `${user?.phoneNumber}`,
  //     status: 'online',
  //   }),
  //   [user]
  // );

  // const messageData = useMemo(
  //   () => ({
  //     id: uuidv4(),
  //     attachments: [],
  //     body: message,
  //     contentType: 'text',
  //     createdAt: sub(new Date(), { minutes: 1 }),
  //     senderId: myContact.id,
  //   }),
  //   [message, myContact.id]
  // );

  // const conversationData = useMemo(
  //   () => ({
  //     id: uuidv4(),
  //     messages: [messageData],
  //     participants: [...recipients, myContact],
  //     type: recipients.length > 1 ? 'GROUP' : 'ONE_TO_ONE',
  //     unreadCount: 0,
  //   }),
  //   [messageData, myContact, recipients]
  // );

  // const handleAttach = useCallback(() => {
  //   if (fileRef.current) {
  //     fileRef.current.click();
  //   }
  // }, []);

  const handleChangeMessage = useCallback((event) => {
    setMessage(event.target.value);
  }, []);


  const handleCreateNewMessage = () => {
    if (message) {
      const credentials = {
        setMessage,
        dispatch, state: {
          "contact_id": selectedConversationId,
          "message_type": "text",
          "text_message": message
        }
      };
      dispatch(createChatMessage(credentials));
    }
  }

  const handleSendMessage = (event) => {
    if (event?.key === 'Enter') {
      handleCreateNewMessage()
    }
  }

  return (
    <>
      <InputBase
        value={message}
        onKeyUp={handleSendMessage}
        onChange={handleChangeMessage}
        placeholder="Type a message"
        disabled={disabled}
        startAdornment={
          <IconButton>
            <Iconify icon="eva:smiling-face-fill" />
          </IconButton>
        }
        endAdornment={
          <div>
            {message ? (
              <Grow in={!!message} timeout={300}>
                <IconButton
                  variant='contained'
                  color='inherit'
                  onClick={handleCreateNewMessage}
                  sx={{
                    backgroundColor: "#22c55d", color: "#fff", display: "flex", alignItems: "center", justifyContent: "center",
                    '&:hover': {
                      color: "#fff",
                      backgroundColor: "#22c55d",
                    },
                  }}
                >
                  {createMsgLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> :
                    <SendIcon fontSize='small' sx={{ fontSize: "16px", ml: '3px' }} />}
                </IconButton>
              </Grow>
            ) : (
              <Stack direction="row" sx={{ flexShrink: 0 }}>
                <IconButton>
                  <Iconify icon="solar:gallery-add-bold" />
                </IconButton>
                <IconButton>
                  <Iconify icon="eva:attach-2-fill" />
                </IconButton>
                <IconButton>
                  <Iconify icon="solar:microphone-bold" />
                </IconButton>
              </Stack>
            )}
          </div>
        }
        sx={{
          px: 1,
          height: 56,
          flexShrink: 0,
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      />

      {/* <input type="file" ref={fileRef} style={{ display: 'none' }} /> */}
    </>
  );
}

ChatMessageInput.propTypes = {
  disabled: PropTypes.bool,
  selectedConversationId: PropTypes.string,
};
