import { CSVLink } from 'react-csv';
import { map, debounce } from 'lodash';
import { enUS } from 'date-fns/locale';
import { DateRange } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { TableRow, TextField, TableCell, InputAdornment, CircularProgress } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { colors } from 'src/theme/colors';
import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { deleteContact, getAllContact, getExcelFullContact } from 'src/server/api/contact';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import CustomPopover from 'src/components/custom-popover/custom-popover';
import {
  useTable,
  // emptyRows,
  TableNoData,
  // TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import DeleteConfirmDialog from 'src/sections/common/DeleteConfirm';

import StudentTableRow from './sections/contact-table-row';
import CreateContactDialog from './sections/create-contact';
import ImportContactDialog from './sections/import-contact-data';


// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ContactAllList() {
  // eslint-disable-next-line react-hooks/exhaustive-deps

  // hooks
  const dispatch = useDispatch();
  const router = useRouter();

  // theme hooks
  const { enqueueSnackbar } = useSnackbar();
  // const theme = useTheme();
  const settings = useSettingsContext();

  // table and custom hooks
  const table = useTable({ defaultOrderBy: 'createdAt', defaultDense: true });
  // const { role } = useGetRoles();
  const confirm = useBoolean();
  const popover = usePopover();
  // const ePopover = usePopover();

  // redux states
  const { contactList, loading, fullContactData } = useSelector((state) => ({
    loading: state.contact.loading,
    contactList: state.contact.contact,
    fullContactData: state.contact.fullContact,
  }));

  console.log(contactList);

  // states 
  const [searchText, setSearchText] = useState();
  const [finalSearchText, setFinalSearchText] = useState();
  const [openContactDetails, setOpenContactDetails] = useState({});
  const [openCreateStudentDialog, setOpenCreateStudentDialog] = useState(false);
  const [openImportDataPopup, setOpenImportDataPopup] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [sortDate, setSortDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);
  const ExcelData = fullContactData

  const [tableData, setTableData] = useState([]);
  const totalPage = contactList?.total;

  const TABLE_HEAD = [
    { id: 'name', label: 'Name' },
    { id: 'whatsapp_number', label: 'Phone' },
  ];

  // Api calls
  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: finalSearchText || '',
      limit: table.rowsPerPage || 20,
      dispatch,
    };
    dispatch(getAllContact(credentials));
  }, [dispatch, finalSearchText, table.page, table.rowsPerPage]);

  useEffect(() => {
    if (totalPage >= 1) {
      const credentials = {
        page: 1,
        limit: totalPage,
        dispatch,
      };
      dispatch(getExcelFullContact(credentials));
    }
  }, [dispatch, totalPage]);


  // useEffect(() => {
  //   const credentials = {
  //     module_type: 'student',
  //     sort: '',
  //     date: '',
  //     search: '',
  //     partner: '',
  //     partner_type: '',
  //     lab_model: '',
  //     status: '',
  //     type: '',
  //     country: '',
  //     classID: '',
  //     dispatch,
  //   };
  //   dispatch(getExcelDetails(credentials));
  // }, [dispatch]);


  // state Update and  use effect function
  useEffect(() => {
    setTableData(contactList?.contacts);
  }, [contactList]);


  // other functions
  const debouncedSetDebouncedText = debounce((value) => setFinalSearchText(value), 1000)

  const handleChangeSearchText = (e) => {
    debouncedSetDebouncedText(e.target.value)
    setSearchText(e.target.value)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleDeleteClick = (item) => {
    setOpenContactDetails(item);
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      contactId: openContactDetails?._id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteContact(credentials));
  };

  // const handleRowClick = (row) => {
  //   console.log(row);
  // };


  // Table Functions

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => { };

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const handleEditClick = (row) => {
    if (row) {
      setOpenContactDetails(row);
    }
    setOpenCreateStudentDialog(true);
  };


  // Excel Download Functions
  const headers = [
    { label: 'sl', key: 'sl' },
    { label: 'Name', key: 'name' },
    { label: 'Whatsapp_number', key: 'whatsapp_number' },
  ];

  const excelDownloadData = map(ExcelData?.contacts, (item, key) => ({
    ...item,
    sl: key,
    name: item?.name,
    whatsapp_number: item?.whatsapp_number,
  }));

  return (
    <>
      <MetaHelmet title="Contacts" />

      {openCreateStudentDialog && (
        <CreateContactDialog
          isUpdate={openContactDetails}
          setOpenStudentId={setOpenContactDetails}
          setOpen={setOpenCreateStudentDialog}
          open={openCreateStudentDialog}
        />
      )}

      {openImportDataPopup && (
        <ImportContactDialog
          setOpen={setOpenImportDataPopup}
          open={openImportDataPopup}
        />
      )}

      {openDeleteConfirmDialog && <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        item={[openContactDetails.name]}
        title="Contact"
      />}

      <Container maxWidth={settings.themeStretch ? false : 'xl'} className='custom-container-padding'>
        <CustomBreadcrumbs
          // heading="Contacts"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },

            {
              name: 'Contacts',
            },
          ]}
          action={
            <Stack direction="row" alignItems="center" >
              <TextField
                fullWidth
                value={searchText}
                className="custom-search-input-new"
                onChange={handleChangeSearchText}
                placeholder="Search Student"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  mx: 1,
                }}
              />
              <Stack direction="row" alignItems="center" justifyContent="space-between" display="flex">
                <Tooltip title='select date rage'>
                  <IconButton
                    sx={{
                      backgroundColor: colors.offWhite,
                      mx: 1,
                      padding: .875
                    }}
                    size='small'
                    onClick={popover.onOpen}

                  >
                    <Iconify icon="fluent:calendar-20-filled" width={16} />
                  </IconButton>
                </Tooltip>

                <Tooltip title='export contact'>
                  <IconButton
                    sx={{
                      backgroundColor: colors.offWhite,
                      mx: 1,
                      padding: .875
                    }}
                    size='small'
                    disabled={totalPage <= 0}
                  >
                    <CSVLink
                      data={excelDownloadData}
                      headers={headers}
                      filename="Contact-List.csv"
                      style={{
                        textDecoration: 'none',
                        color: '#637481',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {/* <Iconify icon="tabler:download" width={16} /> */}
                      <Iconify icon="solar:export-bold" width={16} />
                    </CSVLink>
                  </IconButton>
                </Tooltip>

                <Tooltip title='import contact'>
                  <IconButton
                    sx={{
                      backgroundColor: colors.offWhite,
                      mx: 1,
                      padding: .875
                    }}
                    size='small'
                    onClick={() => setOpenImportDataPopup(true)}
                  >
                    <Iconify icon="hugeicons:file-export" width={16} />
                  </IconButton>
                </Tooltip>

                <Tooltip title='create new contact'>
                  <IconButton
                    size='small'
                    sx={{
                      backgroundColor: colors.offWhite,
                      mx: 1
                    }}
                    onClick={() => setOpenCreateStudentDialog(true)}
                  >
                    <AddRoundedIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          }
          sx={{
            mb: { xs: 3, md: 3 },
          }}
        />


        <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ p: 0 }} arrow='top-right'>
          <Card>
            <DateRange
              editableDateInputs
              onChange={item => setSortDate([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={sortDate}
              locale={enUS}
            />
          </Card>
        </CustomPopover>


        <Card>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="iconamoon:send-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="solar:printer-minimalistic-bold" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {loading ? <TableRow>
                    <TableCell colSpan={TABLE_HEAD?.length} align="center">
                      <CircularProgress size="16px" />
                    </TableCell>
                  </TableRow>
                    : <>
                      {(tableData || []).map((row, index) => (
                        <StudentTableRow
                          index={index}
                          page={table.page + 1}
                          rowsPerPage={table.rowsPerPage || 20}
                          handleEditClick={handleEditClick}
                          handleDeleteClick={handleDeleteClick}
                          key={row.id}
                          row={row}
                          selected={table?.selected?.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onViewRow={() => handleViewRow(row.id)}
                          onEditRow={() => handleEditRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          loading={loading}
                          colSpan={TABLE_HEAD?.length}
                        />
                      ))}</>}
                </TableBody>

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>

      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />

      {/* 
      <CustomPopover
        open={ePopover.open}
        onClose={ePopover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" color={colors.icon} />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:import-bold" color={colors.icon} />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:export-bold" color={colors.icon} />
          Export
        </MenuItem>
      </CustomPopover> */}
    </>
  );
}




//  {/* <Tabs
//             value={type}
//             onChange={(e, newValue) => setType(newValue)}
//             sx={{
//               px: 2.5,
//               boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
//             }}
//           >
//             {TABS?.map((tab) => (
//               <Tab
//                 key={tab.value}
//                 value={tab.value}
//                 label={tab.label}
//                 iconPosition="end"
//                 icon={
//                   <Label   variant={((tab.value === 'all' || tab.value === sort) && 'filled') || 'soft'} color={tab.color}>
//                     {tab.count}
//                   </Label>
//                 }
//               />
//             ))}
//           </Tabs> */}