import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createClient = createAsyncThunk(
  'client/create',
  async ({ state, dispatch, handleClose, handleClear, partnerType }) => {
    try {
      const URL = `/client/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Client Item created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllClient = createAsyncThunk(
  'client/list',
  async ({ page, search, limit, sort, date, dispatch }) => {
    try {
      const URL = `/client/admin/all?page=${page && page}&search=${search && search}&limit=${
        limit && limit}&sort=${sort || ''}&from=${date?.from || ''}&to=${date?.to || ''}`;

      const URL_DROPDOWN = `/client/admin/all`;

      const response = await get(page ? URL : URL_DROPDOWN);
      console.log(response);
      if (response) {
        // return {
        //   response,
        //   // sales: response?.sales,
        //   // total: response?.total,
        //   // page: response?.page,
        // };
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getClientDetails = createAsyncThunk('Client/single', async ({ clientId, dispatch }) => {
  try {
    const response = await get(`/client/admin/${clientId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateClientDetails = createAsyncThunk(
  'Client/update',
  async ({ state, clientId, dispatch, navigate }) => {
    try {
      const URL = `/client/admin/${clientId}`;

      const response = await put(URL, state);
      if (response) {
        if (navigate) {
          navigate();
        }
        dispatch(activeSnack({ type: 'success', message: 'Client Item updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteClient = createAsyncThunk(
  'Client/delete',
  async ({ clientId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/client/admin/${clientId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Client Item deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
