import * as Yup from 'yup';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Stack, Dialog, IconButton, DialogTitle, DialogContent } from '@mui/material';

import { createContact, updateContactDetails } from 'src/server/api/contact';

import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

CreateContactDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenStudentId: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function CreateContactDialog({ open, setOpen, isUpdate, setOpenStudentId }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const { role } = useGetRoles();
  const handleClose = () => {
    setOpen(false);
    setOpenStudentId({});
  };
  const dispatch = useDispatch();
  // const { enqueueSnackbar } = useSnackbar();

  const { loading } = useSelector((state) => ({
    // labModelList: state.labModel.labModel,
  }));

  const SupportMaterialSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    whatsapp_number: Yup.string()
      .required('Whatsapp number is required')
      .max(10, 'Whatsapp number must be 10')
      .min(10, 'Whatsapp number must be 10'),
  });

  const defaultValues = useMemo(() => {
    if (isUpdate?._id) {
      return {
        name: isUpdate?.name || '',
        whatsapp_number: isUpdate?.whatsapp_number || '',
      };
    }
    return {
      name: '',
      whatsapp_number: '',
    };
  }, [isUpdate]);

  console.log(isUpdate);

  const methods = useForm({
    resolver: yupResolver(SupportMaterialSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  console.log(errors);
  const handleClear = () => {
    reset(defaultValues);
  };

  const onSubmit = handleSubmit(async (data) => {
    const CreateCredentials = {
      state: data,
      dispatch,
      handleClose,
      handleClear,
    };

    const UpdateCredentials = {
      state: data,
      contactId: isUpdate?._id,
      dispatch,
      handleClose,
      handleClear,
    };

    if (isUpdate?._id) {
      dispatch(updateContactDetails(UpdateCredentials));
    } else {
      dispatch(createContact(CreateCredentials));
    }
  });

  const renderCommissionForm = (
    <Stack spacing={3} sx={{ p: 1, px: 0 }}>
      <Stack spacing={1.5}>
        <RHFTextField name="name" required label="Name" type="text" placeholder="Name" />
      </Stack>
      <Stack spacing={1.5}>
        <RHFTextField
          required
          name="whatsapp_number"
          type="number"
          placeholder="Whatsapp number"
          label="Whatsapp number"
        />
      </Stack>

      <Stack sx={{ marginBottom: '1rem' }} justifyContent="end" alignItems="center">
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          loading={isSubmitting || loading}
          sx={{ color: 'white', ml: 'auto' }}
          color="success"
        >
          Submit
        </LoadingButton>
      </Stack>
    </Stack>
  );
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {isUpdate?._id ? 'Update Contact' : 'Create Contact'}
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{
              p: 0.5,
              top: 10,
              right: 10,
              transform: 'scale(0.8)',
              position: 'absolute',
              color: 'common.white',
              bgcolor: (theme1) => alpha(theme1.palette.grey[900], 0.48),
              '&:hover': {
                bgcolor: (theme1) => alpha(theme1.palette.grey[900], 0.72),
              },
            }}
          >
            <Iconify icon="mingcute:close-line" width={14} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minWidth: '450px' }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderCommissionForm}
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
