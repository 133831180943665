// eslint-disable-next-line import/no-unresolved
import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from './http';

export const getDashboardData = createAsyncThunk(
  'static/dashboard',
  async ({ enqueueSnackbar }) => {
    try {
      const response = await get('/static/admin/dashboard/data');
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// partner charts
export const getPartnerEarningChartData = createAsyncThunk(
  'earning/partner',
  async ({ enqueueSnackbar, year  }) => {
    try {
      const response = await get(`earning/partner/graph/monthly?&year=${year || ''}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getPartnerSalesChartData = createAsyncThunk(
  'sale/partner',
  async ({ enqueueSnackbar, year }) => {
    try {
      const response = await get(`sale/partner/graph/monthly?&year=${year || ''}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
