import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
// import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Typography, ListItemText, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { fDate, fTime } from 'src/utils/format-time';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function StudentTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  loading,
  colSpan,
  handleEditClick,
  index,
  page,
  rowsPerPage,
}) {
  const { name, phone, email, _id, createdAt } = row;
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  const confirm = useBoolean();
  const popover = usePopover();
  const { role } = useGetRoles();
  const navigate = useNavigate();

  const handleDeleteConfirmClick = (id) => {
    // const credentials = {
    //   StudentId: id,
    //   dispatch,
    // };

    // dispatch(deleteStudent(credentials));
    confirm.onFalse();
  };
  const handleViewClick = (id) => {
    navigate(paths.dashboard.student_view(id), {
      state: id,
    });
    // handleCloseMenu();
  };
  return (
    <>
      {loading ? (
        <TableRow>
          <TableCell colSpan={colSpan + 1} align="center">
            <CircularProgress size="16px" />
          </TableCell>
        </TableRow>
      ) : (
        <TableRow hover selected={selected}>
          {/* <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={onSelectRow} />
          </TableCell> */}
          <TableCell>
            <Typography sx={{ textTransform: 'capitalize', marginLeft: '5px' }} variant="caption">
              {/* {index + 1}    */}
              {(page - 1) * rowsPerPage + index + 1}
            </Typography>
          </TableCell>

          <TableCell>
            <ListItemText
              primary={fDate(createdAt)}
              secondary={fTime(createdAt)}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          </TableCell>

          <TableCell>{name}</TableCell>

          <TableCell align="left">
            <Typography variant="caption">{phone}</Typography>
          </TableCell>

          <TableCell align="left">
            <Typography variant="caption">{email}</Typography>
          </TableCell>

          {role === 'super_admin' && (
            <TableCell align="right" sx={{ px: 1 }}>
              <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                <Iconify icon="eva:more-vertical-fill" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      )}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        {/* <MenuItem>
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem> */}

        {/* <MenuItem onClick={() => handleEditClick(row)}>
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem> */}
        <MenuItem onClick={() => handleViewClick(_id)}>
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={`Are you sure want to delete '${name}' ?`}
        action={
          <Button variant="contained" color="error" onClick={() => handleDeleteConfirmClick(_id)}>
            Delete
          </Button>
        }
      />
    </>
  );
}

StudentTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  colSpan: PropTypes.number,
  handleEditClick: PropTypes.any,
  index: PropTypes.any,
  rowsPerPage: PropTypes.any,
  page: PropTypes.any,
};
