export const colors = {
  primary: '#193028',
  secondary: '#00843D',
  white: '#ffffff',
  black: '#000000',
  light: '#05D38E',
  exLight: '#E9F6D5',
  green: '#418e8c',
  offWhite: '#F1F1F4',
  icon: '#a5b4ab',
  iconLight: '#919eabcc',
};

// export const colors = {
//   primary: '#B63422',
//   secondary: '#CF7761',
//   white: '#ffffff',
//   black: '#000000',
//   light: '#E4AE92',
//   exLight: '#FEF5CE',
//   green: '#418e8c',
// };
