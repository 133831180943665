import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import { createClient, getAllClient, deleteClient, getClientDetails, updateClientDetails } from '../api/client';

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    loading: false,
    error: {},
    // client
    client: {},
    staffDropList: [],
    clientDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create client details
    [createClient.pending]: (state) => {
      state.loading = true;
    },
    [createClient.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.clientItems;
      const oldClientList = jsonState?.clientItems;

      const modifiedClientItemList = {
        ...jsonState,
        clientItems: Array.isArray(oldClientList) ? [action.payload, ...oldClientList] : [action.payload],
      };
      state.loading = false;
      state.clientDetails = action.payload;
      state.client = modifiedClientItemList;
      state.error = {};
    },
    [createClient.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all client
    [getAllClient.pending]: (state) => {
      state.loading = true;
    },
    [getAllClient.fulfilled]: (state, action) => {
      state.loading = false;
      state.client = action.payload;
      state.error = {};
    },
    [getAllClient.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single client details
    [getClientDetails.pending]: (state) => {
      state.loading = true;
    },
    [getClientDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.clientDetails = action.payload;
      state.error = {};
    },
    [getClientDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update client details
    [updateClientDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateClientDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.clientDetails = action.payload;
      state.error = {};
    },
    [updateClientDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete client
    [deleteClient.pending]: (state) => {
      state.loading = true;
    },
    [deleteClient.fulfilled]: (state, action) => {
      console.log('Action Payload:', action.payload);
      const jsonState = covertToJSON(state)?.clients;
      console.log('JSON State :', jsonState);

      const modifiedClientItemList = {
        ...jsonState,
        clientItems: jsonState.clientItems?.filter((client) => client._id !== action.payload),
      };
      state.loading = false;
      state.client = modifiedClientItemList;
      state.error = {};
    },
    [deleteClient.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = clientSlice.actions;

export default clientSlice.reducer;
