import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/routes/paths';

import { getSingleChat, getAllChatList } from 'src/server/api/chat';

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import ChatNav from '../chat-nav';
import ChatRoom from '../chat-room';
import ChatMessageList from '../chat-message-list';
import ChatMessageInput from '../chat-message-input';
import ChatHeaderDetail from '../chat-header-detail';

// ----------------------------------------------------------------------

export default function ChatView() {
  const dispatch = useDispatch()

  const { chatList, loading, chatView } = useSelector((state) => ({
    loading: state.chat.loading,
    chatList: state.chat.chatList,
    chatView: state.chat.chatView,
  }));

  const [selectedConversation, setSelectedConversation] = useState('')
  const [searchContact, setSearchContact] = useState('')

  const conversations = chatList?.contacts
  const singleChatDetails = selectedConversation
  const singleChatMessages = chatView?.messages

  // chat list

  const limit = 20
  const totalItems = chatList?.total || 0;
  const allPages = Math.ceil(totalItems / limit);

  const [page, setPage] = useState(1)

  const [paginatedChatListData, setPaginatedChatListData] = useState([])

  const handleFetchNextPage = () => {
    if (page < allPages && paginatedChatListData?.length < chatList?.total) {
      setPage(page + 1)
    }
  }
  useEffect(() => {
    setPaginatedChatListData((prevData) => {
      const validConversations = Array.isArray(conversations) ? conversations : [];
      const filteredData = validConversations.filter((item) => !prevData.some(prevItem => prevItem._id === item._id));
      return [...prevData, ...filteredData];
    });
  }, [conversations]);


  useEffect(() => {
    const credentials = {
      dispatch,
      search: searchContact,
      page,
    };
    dispatch(getAllChatList(credentials));
  }, [dispatch, page, searchContact]);



  // message list
  const msgLimit = 20
  const totalMsgItems = chatView?.total || 0;
  const allMsgPages = Math.ceil(totalMsgItems / msgLimit);

  const [msgPage, setMsgPage] = useState(1)

  const [paginatedMsgListData, setPaginatedMsgListData] = useState([])

  const handleFetchNextMsgPage = () => {
    if (msgPage < allMsgPages && paginatedMsgListData?.length < chatView?.total) {
      setMsgPage(msgPage + 1)
    }
  }
  useEffect(() => {
    setPaginatedMsgListData((prevData) => {
      const validSingleChatMessages = Array.isArray(singleChatMessages) ? singleChatMessages : [];
      const filteredData = validSingleChatMessages.filter((item) => !prevData.some(prevItem => prevItem._id === item._id));
      return [...prevData, ...filteredData];
    });
  }, [singleChatMessages]);


  useEffect(() => {
    if (selectedConversation) {
      setMsgPage(1)
      setPaginatedMsgListData([])
    }

  }, [selectedConversation])



  console.log(paginatedMsgListData);


  useEffect(() => {
    if (selectedConversation?._id) {
      const credentials = {
        dispatch,
        chatId: selectedConversation?._id,
        page: msgPage
      };
      dispatch(getSingleChat(credentials));
    }

  }, [dispatch, msgPage, selectedConversation]);
  // end


  // single chat header
  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      flexShrink={0}
      sx={{ pr: 1, pl: 2.5, py: 1, minHeight: 72 }}
    >
      {selectedConversation && !loading ? (
        <ChatHeaderDetail selectedConversation={selectedConversation} />
      ) : (
        ''
      )}
    </Stack>
  );

  // chat screen side bar and chat list
  const renderNav = (
    <ChatNav
      conversations={paginatedChatListData}
      loading={loading}
      selectedConversation={selectedConversation}
      setSelectedConversationId={setSelectedConversation}
      setSearchContact={setSearchContact}
      searchContact={searchContact}
      handleFetchNextPage={handleFetchNextPage}
    />
  );

  const renderMessages = (
    <Stack
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <ChatMessageList handleFetchNextMsgPage={handleFetchNextMsgPage} singleChatMessages={paginatedMsgListData} />

      <ChatMessageInput
        selectedConversationId={selectedConversation?._id}
        disabled={!selectedConversation}
      />
    </Stack>
  );

  return (
    <Container maxWidth="xl" >
      <CustomBreadcrumbs
        // heading="Contacts"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },

          {
            name: 'Chats',
          },
        ]}
        action={
          <Stack direction="row" alignItems="center" >
            <Stack direction="row" alignItems="center" justifyContent="space-between" display="flex">
              &nbsp;
              {/* other options */}
            </Stack>
          </Stack>
        }
        sx={{
          mb: { xs: 3, md: 3 },
        }}
      />

      <Stack component={Card} direction="row" sx={{ height: '80vh' }}>
        {renderNav}

        <Stack
          sx={{
            width: 1,
            height: 1,
            overflow: 'hidden',
          }}
        >
          {renderHead}

          <Stack
            direction="row"
            sx={{
              width: 1,
              height: 1,
              overflow: 'hidden',
              borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          >
            {renderMessages}

            <ChatRoom selectedConversation={singleChatDetails} />
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
