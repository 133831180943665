import React from 'react';
import PropType from 'prop-types';

import { Stack } from '@mui/material';

import { colors } from 'src/theme/colors';

import Iconify from '../iconify';

function IconsX({ icon, bgColor }) {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      borderRadius={1}
      mr={2}
      sx={{ backgroundColor: bgColor, color: colors.white, padding: 0.5 }}
    >
      <Iconify icon={icon} />
    </Stack>
  );
}

export default IconsX;
IconsX.propTypes = {
  icon: PropType.any,
  bgColor: PropType.string,
};
