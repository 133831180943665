import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import ChatPage from 'src/pages/Client/chat';
import Terms from 'src/pages/Common/Static/terms';
import AuthModernLayout from 'src/layouts/auth/modern';
import LogoutPage from 'src/pages/Common/Auth/LogoutPage';
import PrivacyPolicy from 'src/pages/Common/Static/privacy';
import ContactAllList from 'src/pages/Client/contact/Contact';
import NewPasswordPage from 'src/pages/Common/Auth/NewPassword';
import ForgotPasswordPage from 'src/pages/Common/Auth/ForgotPassword';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
import { ModernLoginView, ModernRegisterView } from 'src/layouts/modern';

import { SplashScreen } from 'src/components/loading-screen';

import CreatePassword from 'src/sections/auth/new-password-view';

const CompactLayout = lazy(() => import('src/layouts/compact'));
const Page500 = lazy(() => import('src/pages/Common/Static/500'));
const Page403 = lazy(() => import('src/pages/Common/Static/403'));
const Page404 = lazy(() => import('src/pages/Common/Static/404'));

const publicRoutes = [
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
      { path: 'terms', element: <Terms /> },
      { path: 'privacy', element: <PrivacyPolicy /> },
      // { path: 'landing', element: <LandingView /> },
    ],
  },
];

const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),

    children: [
      {
        path: 'login',
        element: <Outlet />,
        children: [
          {
            path: 'member',
            element: (
              <AuthModernLayout>
                <ModernLoginView />
              </AuthModernLayout>
            ),
          },
        ],
      },

      {
        path: 'register',
        element: (
          <AuthModernLayout type="mini">
            <ModernRegisterView />
          </AuthModernLayout>
        ),
      },

      {
        path: 'logout',
        element: <LogoutPage />,
      },
      {
        path: 'forgot-password',
        element: (
          <AuthModernCompactLayout>
            <ForgotPasswordPage />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create-password/:type/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="create" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'password/reset/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="reset" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create-password/:id',
        element: (
          <AuthModernCompactLayout>
            <CreatePassword />
          </AuthModernCompactLayout>
        ),
      },

      { path: '404', element: <Page404 isInner /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

// super admin
const adminRoutes = [];

// member / client
const memberRoutes = [
  {
    path: 'contacts',
    element: <ContactAllList />,
  },
  {
    path: 'chat',
    element: <ChatPage />,
  },
  // { path: 'student/:id', element: <StudentSingleViewPage /> },
];

export { authRoutes, adminRoutes, publicRoutes, memberRoutes };
