import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

// for both admin and member
export const createBulkContacts = createAsyncThunk(
  'contact/bulk/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/contact/admin/bulk/new`;
      const response = await post(URL, state);
      if (response?.success) {
        handleClose();
        dispatch(activeSnack({ type: 'success', message: response?.data?.message }));
        return state?.contacts;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// for both admin and member
export const createContact = createAsyncThunk(
  'contact/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/contact/admin/new`;
      const response = await post(URL, state);
      if (response?.success) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Contact created successfully' }));
        return response?.data;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// for both admin and member
export const updateContactDetails = createAsyncThunk(
  'contact/update',
  async ({ state, contactId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/contact/admin/${contactId}`;

      const response = await put(URL, state);
      if (response?.success) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Contact updated Successfully' }));
        return response?.data;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// for both admin and member
export const getAllContact = createAsyncThunk(
  'contact/list',
  async ({ page, search, limit, dispatch }) => {
    try {
      const URL = `/contact/admin/all?page=${page}&limit=${limit}&search=${search || ''}`;

      const response = await get(URL);
      if (response?.success) {
        return response?.data;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// for both admin and member
export const getExcelFullContact = createAsyncThunk(
  'contact/excel/list',
  async ({ page, limit, dispatch }) => {
    try {
      const URL = `/contact/admin/all?page=${page}&limit=${limit}`;

      const response = await get(URL);
      if (response?.success) {
        return response?.data;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// for both admin and member
export const getContactDetails = createAsyncThunk(
  'contact/single',
  async ({ clientId, dispatch }) => {
    try {
      const response = await get(`/client/admin/${clientId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// for both admin and member
export const deleteContact = createAsyncThunk(
  'contact/delete',
  async ({ contactId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/contact/admin/${contactId}/`);
      if (response?.success) {
        handleCloseDeleteDialog();
        if (response?.data?.message) {
          dispatch(
            activeSnack({
              type: 'warning',
              message: response?.data?.message,
            })
          );
        } else {
          dispatch(
            activeSnack({
              type: 'success',
              message: 'Contact deleted Successfully',
            })
          );
        }
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
