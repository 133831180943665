import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import Iconify from 'src/components/iconify';

import { useCollapseNav } from './hooks';
import ChatNavItem from './chat-nav-item';
import ChatNavAccount from './chat-nav-account';
import { ChatNavItemSkeleton } from './chat-skeleton';

// ----------------------------------------------------------------------

const NAV_WIDTH = 320;

const NAV_COLLAPSE_WIDTH = 96;

export default function ChatNav({ loading, conversations, selectedConversation, setSelectedConversationId, setSearchContact, searchContact, handleFetchNextPage }) {
  const theme = useTheme();

  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const {
    collapseDesktop,
    onCloseDesktop,
    onCollapseDesktop,
    //
    openMobile,
    onCloseMobile,
  } = useCollapseNav();



  useEffect(() => {
    if (!mdUp) {
      onCloseDesktop();
    }
  }, [onCloseDesktop, mdUp]);

  const handleToggleNav = useCallback(() => {
    if (mdUp) {
      onCollapseDesktop();
    } else {
      onCloseMobile();
    }
  }, [mdUp, onCloseMobile, onCollapseDesktop]);

  const handleClickCompose = useCallback(() => {
    if (!mdUp) {
      onCloseMobile();
    }
    router.push(paths.dashboard.chat);
  }, [mdUp, onCloseMobile, router]);

  const handleSearchContacts = (searchText) => {
    setSearchContact(searchText)
  }


  const renderSkeleton = (
    <>
      {[...Array(12)].map((_, index) => (
        <ChatNavItemSkeleton key={index} />
      ))}
    </>
  );

  const renderList = (
    <>
      {conversations?.map((conversation) => (
        <ChatNavItem
          key={conversation?._id}
          collapse={collapseDesktop}
          conversation={conversation}
          selected={conversation?._id === selectedConversation?._id}
          onCloseMobile={onCloseMobile}
          setSelectedConversationId={setSelectedConversationId}
        />
      ))}
    </>
  );

  const renderSearchInput = (
    <TextField
      fullWidth
      size='small'
      value={searchContact}
      onChange={(event) => handleSearchContacts(event.target.value)}
      placeholder="Search contacts..."
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
          </InputAdornment>
        ),
      }}
      sx={{ mt: 2.5 }}
    />
  );



  const renderContent = (
    <>
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ p: 2.5, pb: 0 }}>
        {!collapseDesktop && (
          <>
            {/* current user section */}
            <ChatNavAccount />
            <Box sx={{ flexGrow: 1 }} />
          </>
        )}

        <IconButton onClick={handleToggleNav}>
          <Iconify
            icon={collapseDesktop ? 'eva:arrow-ios-forward-fill' : 'eva:arrow-ios-back-fill'}
          />
        </IconButton>

        {!collapseDesktop && (
          <IconButton onClick={handleClickCompose}>
            <Iconify width={24} icon="solar:user-plus-bold" />
          </IconButton>
        )}
      </Stack>


      <Box sx={{ p: 2.5, pt: 0 }}>{!collapseDesktop && renderSearchInput}</Box>

      <InfiniteScroll
        dataLength={conversations?.length || 0}
        next={handleFetchNextPage}
        hasMore
        loader={loading && renderSkeleton}
        endMessage={<p style={{ textAlign: 'center' }}><b>You have seen it all</b></p>}
        scrollableTarget="scrollableDiv"
      >
        <div id="scrollableDiv" style={{ height: '500px', overflowY: 'auto' }}>
          {loading && renderSkeleton}

          {!!conversations?.length && renderList}
        </div>
      </InfiniteScroll>

    </>
  );

  return (
    <>

      {/* // mobile view */}
      {mdUp ? (
        <Stack
          sx={{
            height: 1,
            flexShrink: 0,
            width: NAV_WIDTH,
            borderRight: `solid 1px ${theme.palette.divider}`,
            transition: theme.transitions.create(['width'], {
              duration: theme.transitions.duration.shorter,
            }),
            ...(collapseDesktop && {
              width: NAV_COLLAPSE_WIDTH,
            }),
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        // {/* // full view */}
        <Drawer
          open={openMobile}
          onClose={onCloseMobile}
          slotProps={{
            backdrop: { invisible: true },
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}

ChatNav.propTypes = {
  conversations: PropTypes.object,
  loading: PropTypes.bool,
  setSelectedConversationId: PropTypes.func,
  selectedConversation: PropTypes.any,
  setSearchContact: PropTypes.func,
  handleFetchNextPage: PropTypes.func,
  searchContact: PropTypes.string,
};
