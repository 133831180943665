import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, post } from './http';
import { activeSnack } from '../store/common';

//
export const getAllChatList = createAsyncThunk(
  'chat/list/all',
  async ({ dispatch, search, page }) => {
    try {
      const URL = `/chat/client/list?page=${page || 1}&limit=${20}&search=${search || ''}`;
      const response = await get(URL);
      if (response?.success) {
        console.log(response?.data);
        return response?.data;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSingleChat = createAsyncThunk('chat/single', async ({ dispatch, chatId, page }) => {
  try {
    const URL = `/chat/client/contact/${chatId}?page=${page || 1}&limit=${20}`;
    const response = await get(URL);
    if (response?.success) {
      return response?.data;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const createChatMessage = createAsyncThunk(
  'chat/create/message',
  async ({ dispatch, state, setMessage }) => {
    try {
      const URL = `/chat/client/new`;
      const response = await post(URL, state);
      if (response?.success) {
        setMessage('');
        return response?.data;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
