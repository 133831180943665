import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Iconify from 'src/components/iconify';


// ----------------------------------------------------------------------

export default function ChatMessageItem({ message, onOpenLightbox }) {
  const renderInfo = (
    <Typography
      noWrap
      variant="caption"
      sx={{
        mb: 0,
        color: 'text.disabled',
        ...(message?.direction === 'out' && {
          color: '#009688'
        }),
        fontSize: "10px",
        ml: 2
      }}
      textAlign="end"
    >
      {formatDistanceToNowStrict(new Date(message?.createdAt), {
        addSuffix: true,
      })}
    </Typography>
  );

  const renderBody = (
    <Stack
      sx={{
        padding: "6px 10px",
        minWidth: 100,
        maxWidth: 320,
        borderRadius: 1,
        typography: 'body2',
        bgcolor: 'background.neutral',
        ...(message?.direction === 'out' && {
          color: 'grey.800',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {/* <Box
        component="img"
        alt="attachment"
        src=""
        onClick={() => onOpenLightbox('')}
        sx={{
          minHeight: 220,
          borderRadius: 1.5,
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
      /> */}
      <Stack sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        minWidth: "150px",
        flexWrap: "wrap"
      }}>

        {message?.text_message}
        {renderInfo}
      </Stack>
    </Stack>
  );

  const renderActions = (
    <Stack
      direction="row"
      className="message-actions"
      sx={{
        pt: 0.5,
        opacity: 0,
        top: '100%',
        left: 0,
        position: 'absolute',
        transition: (theme) =>
          theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
          }),
        ...(message?.direction === 'out' && {
          left: 'unset',
          right: 0,
        }),
      }}
    >
      <IconButton size="small">
        <Iconify icon="solar:reply-bold" width={16} />
      </IconButton>
      <IconButton size="small">
        <Iconify icon="eva:smiling-face-fill" width={16} />
      </IconButton>
      <IconButton size="small">
        <Iconify icon="solar:trash-bin-trash-bold" width={16} />
      </IconButton>
    </Stack>
  );

  return (
    <Stack direction="row" justifyContent={message?.direction === 'out' ? 'flex-end' : 'unset'} sx={{ mb: 5 }}>
      <Stack alignItems="flex-end">
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            position: 'relative',
            '&:hover': {
              '& .message-actions': {
                opacity: 1,
              },
            },
          }}
        >
          {renderBody}
          {renderActions}
        </Stack>
      </Stack>
    </Stack>
  );
}

ChatMessageItem.propTypes = {
  message: PropTypes.object,
  onOpenLightbox: PropTypes.func,
};
