import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { parseISO, differenceInHours } from 'date-fns';

import Box from '@mui/material/Box';
import { Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import CircularProgress from '@mui/material/CircularProgress';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDate } from 'src/utils/format-time';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function ChatRoomSingle({ selectedConversation }) {
  const collapse = useBoolean(true);

  const [progress, setProgress] = useState(0);
  const [sessionNotification, setSessionNotification] = useState('')

  useEffect(() => {
    const calculateProgress = () => {
      const startDate = parseISO(selectedConversation?.session_start_date);
      const now = new Date();
      const hoursDifference = differenceInHours(now, startDate);

      if (hoursDifference <= 0) {
        setProgress(100);
        setSessionNotification('')
      } else if (hoursDifference >= 24) {
        setProgress(0);
        setSessionNotification('Session Expired!')
      } else {
        const percentage = ((24 - hoursDifference) / 24) * 100;
        setSessionNotification('')
        setProgress(percentage);
      }
    };

    calculateProgress();

    const intervalId = setInterval(() => {
      calculateProgress();
    }, 60 * 1000);

    return () => clearInterval(intervalId);
  }, [selectedConversation?.session_start_date]);

  const handleProgressColor = () => {
    switch (progress) {
      case 100:
        return 'success'
      case 50:
        return 'info'

      case 25:
        return 'warning'
      case 5:
        return 'error'

      default:
        return 'success'
    }
  }

  const renderInfo = (
    <Stack alignItems="center" sx={{ py: 5 }}>
      <Stack sx={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          size={80}
          value={progress}
          thickness={2}
          color={handleProgressColor()}
          sx={{ position: "absolute", zIndex: 9 }}
        />
        <Avatar src="" sx={{ width: 80, height: 80, mb: 2 }} />
      </Stack>
      <Typography variant="subtitle1">{selectedConversation?.name}</Typography>
      {sessionNotification && <Typography variant='caption' color="#fc2d00" mb={1}>{sessionNotification}</Typography>}
      {selectedConversation?.is_active ? <Chip label="Active" variant='outlined' size='small' color='success' />
        : <Chip label="Inactive" variant='outlined' size='small' color='default' />}
    </Stack>
  );

  const renderBtn = (
    <ListItemButton
      onClick={collapse.onToggle}
      sx={{
        pl: 2.5,
        pr: 1.5,
        height: 40,
        flexShrink: 0,
        flexGrow: 'unset',
        typography: 'overline',
        color: 'text.secondary',
        bgcolor: 'background.neutral',
      }}
    >
      <Box component="span" sx={{ flexGrow: 1 }}>
        Information
      </Box>
      <Iconify
        width={16}
        icon={collapse.value ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      />
    </ListItemButton>
  );

  const renderContent = (
    <Stack
      spacing={2}
      sx={{
        px: 2,
        py: 2.5,
        '& svg': {
          mr: 1,
          flexShrink: 0,
          color: 'text.disabled',
        },
      }}
    >
      <Stack direction="row">
        <Iconify icon="solar:phone-bold" />
        <Typography variant="body2">{selectedConversation?.whatsapp_number}</Typography>
      </Stack>
      <Stack direction="column" sx={{ ml: 3.5 }}>
        <Typography variant="body2">     Session Started at :</Typography>
        <Typography variant="caption">{fDate(selectedConversation?.session_start_date)}</Typography>
      </Stack>

      {/* <Stack direction="row">
        <Iconify icon="mingcute:location-fill" />
        <Typography variant="body2">address</Typography>
      </Stack>
      <Stack direction="row">
        <Iconify icon="fluent:mail-24-filled" />
        <Typography variant="body2" noWrap>
          email
        </Typography>
      </Stack> */}
    </Stack>
  );

  return (
    <>
      {renderInfo}

      {renderBtn}

      <div>
        <Collapse in={collapse.value}>{renderContent}</Collapse>
      </div>
    </>
  );
}

ChatRoomSingle.propTypes = {
  selectedConversation: PropTypes.object,
};
