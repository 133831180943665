import PropTypes from 'prop-types';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import NewPasswordView from 'src/sections/auth/new-password-view';

// ----------------------------------------------------------------------

export default function NewPasswordPage({type}) {
  return (
    <>
      <MetaHelmet title="Create Password" />

      <NewPasswordView type={type}/>
    </>
  );
}
NewPasswordPage.propTypes = {
  type: PropTypes.any,
 
};