
import { Link } from "react-router-dom";

import { paths } from "src/routes/routes/paths";

import hero from "../../../assets/landing/landingImg.jpg"

// ----------------------------------------------------------------------

export default function LandingView() {
  return (
    <>
      {/* <LandingHero /> */}
      <Link to={paths.auth.login_member}>
        <img src={hero} alt='landing img' />
      </Link>
      {/* <Footer /> */}
    </>
  );
}
