import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { useSetRole, useSetToken } from 'src/hooks/useHandleSessions';

import { get, put, post } from './http';
import { activeSnack } from '../store/common';

// for both admin and client
export const forgotPassword = createAsyncThunk(
  'auth/password/forgot',
  async ({ state, enqueueSnackbar }) => {
    try {
      const response = await post(`/admin/password/forgot`, state);
      if (response) {
        enqueueSnackbar('Email Sent Successfully!');
        useSetToken(response?.token);
        return response?.data;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// for both admin and client
export const updatePassword = createAsyncThunk(
  'auth/password/update',
  async ({ state, enqueueSnackbar }) => {
    try {
      const response = await post(`/admin/password/update`, state);
      console.log(response);
      if (response?.success) {
        enqueueSnackbar('Password Updated Successfully!');
        useSetToken(response?.data?.token);
        return response?.data;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// for both admin and client
export const clientLoginApi = createAsyncThunk(
  'auth/user/login',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('/client/login', state);
      if (response?.success) {
        dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));
        useSetToken(response?.data?.token);
        useSetRole('member');
        navigate(paths.dashboard.root, { replace: true });
        return response?.data;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// for client register
export const registerApi = createAsyncThunk(
  'auth/register',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('/client/register', state);
      if (response?.success) {
        dispatch(activeSnack({ type: 'success', message: 'Registration Successfully Completed' }));
        // navigate(paths.auth.login, { replace: true });
        useSetToken(response?.data?.token);
        useSetRole('member');
        navigate(paths.dashboard.root, { replace: true });
        return response?.data;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// for both admin and client
export const logoutApi = createAsyncThunk('auth/logout', async ({ dispatch, navigate }) => {
  try {
    const response = await get('/logout');
    if (response) {
      sessionStorage.clear();
      dispatch(activeSnack({ type: 'success', message: 'logged out successfully' }));
      navigate('/', { replace: true });
      return response?.data;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    sessionStorage.clear();
    navigate('/', { replace: true });
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

// for both admin and client
export const getUserProfile = createAsyncThunk('user/profile', async ({ enqueueSnackbar }) => {
  try {
    const response = await get(`/admin/me`);
    console.log(response);
    if (response?.success) {
      return response?.data;
    }
    return enqueueSnackbar('something went wrong', { variant: 'error' });
  } catch (error) {
    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    throw error?.response?.data?.message;
  }
});

export const updateUserProfile = createAsyncThunk(
  'user/profile/update',
  async ({ enqueueSnackbar, state }) => {
    try {
      const response = await put(`/user/update`, state);
      if (response?._id) {
        enqueueSnackbar('Profile Updated Successfully');
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const passwordReset = createAsyncThunk(
  'auth/password/reset',
  async ({ state, enqueueSnackbar, router, token, type }) => {
    try {
      const response = await post(`/password/reset/${token}`, state);

      if (response) {
        enqueueSnackbar('Password Updated Successfully!');
        switch (type) {
          case 'admin':
            return router.push(paths.auth.login);
          case 'partner':
            return router.push(paths.auth.login_member);
          case 'school':
            return router.push(paths.auth.login_school);

          default:
            router.push(paths.auth.login);
        }
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
