import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createContact,
  deleteContact,
  getAllContact,
  getContactDetails,
  createBulkContacts,
  getExcelFullContact,
  updateContactDetails,
} from '../api/contact';

export const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    loading: false,
    error: {},
    contact: {},
    fullContact: {},
    contactDetails: {},
  },
  reducers: {},
  extraReducers: {
    // upload bulk contact details
    [createBulkContacts.pending]: (state) => {
      state.loading = true;
    },

    [createBulkContacts.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.contact;
      const oldContactList = jsonState?.contacts;

      const modifiedList = {
        ...jsonState,
        contacts: Array.isArray(oldContactList)
          ? [...action.payload, ...oldContactList]
          : [...action.payload],
      };
      state.loading = false;
      state.contact = modifiedList;
      state.error = {};
    },

    [createBulkContacts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // create contact details
    [createContact.pending]: (state) => {
      state.loading = true;
    },

    [createContact.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.contact;
      const oldContactList = jsonState?.contacts;

      const modifiedList = {
        ...jsonState,
        contacts: Array.isArray(oldContactList)
          ? [action.payload, ...oldContactList]
          : [action.payload],
      };
      state.loading = false;
      state.contact = modifiedList;
      state.error = {};
    },

    [createContact.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update contact details
    [updateContactDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateContactDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.contact;
      const oldContactList = jsonState?.contacts;

      const modifiedList = {
        ...jsonState,
        contacts: oldContactList?.map((contact) =>
          contact._id === action.payload?._id ? action.payload : contact
        ),
      };
      state.contact = modifiedList;

      state.loading = false;
      state.contactDetails = action.payload;
      state.error = {};
    },
    [updateContactDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all contact
    [getAllContact.pending]: (state) => {
      state.loading = true;
    },
    [getAllContact.fulfilled]: (state, action) => {
      state.loading = false;
      state.contact = action.payload;
      state.error = {};
    },
    [getAllContact.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all contact
    [getExcelFullContact.pending]: (state) => {
      state.loading = true;
    },
    [getExcelFullContact.fulfilled]: (state, action) => {
      state.loading = false;
      state.fullContact = action.payload;
      state.error = {};
    },
    [getExcelFullContact.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single contact details
    [getContactDetails.pending]: (state) => {
      state.loading = true;
    },
    [getContactDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.contactDetails = action.payload;
      state.error = {};
    },
    [getContactDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // delete contact
    [deleteContact.pending]: (state) => {
      state.loading = true;
    },
    [deleteContact.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.contact;
      const oldContactList = jsonState?.contacts;

      const modifiedList = {
        ...jsonState,
        contacts: oldContactList?.filter((client) => client._id !== action.payload),
      };
      state.loading = false;
      state.contact = modifiedList;
      state.error = {};
    },
    [deleteContact.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

// export const { clearStaffError } = contactSlice.actions;

export default contactSlice.reducer;
