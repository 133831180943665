import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import authReducer from './auth';
import chatReducer from './chat';
import clientReducer from './client';
import commonReducer from './common';
import contactReducer from './contact';
import dashboardReducer from './dashboard';

export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    client: clientReducer,
    contact: contactReducer,
    chat: chatReducer,
  },
});
