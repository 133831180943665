import { createSlice } from '@reduxjs/toolkit';

import { getSingleChat, getAllChatList, createChatMessage } from '../api/chat';

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    loading: false,
    singleChatLoading: false,
    createMsgLoading: false,
    error: {},
    chatList: {},
    chatView: {},
    chatMessage: {},
  },
  reducers: {},
  extraReducers: {
    // create contact details
    [createChatMessage.pending]: (state) => {
      state.createMsgLoading = true;
    },

    [createChatMessage.fulfilled]: (state, action) => {
      state.createMsgLoading = false;
      state.chatMessage = action.payload;
      state.error = {};
    },

    [createChatMessage.rejected]: (state, action) => {
      state.createMsgLoading = false;
      state.error = action.error;
    },

    // get all chatList
    [getAllChatList.pending]: (state) => {
      state.loading = true;
    },
    [getAllChatList.fulfilled]: (state, action) => {
      state.loading = false;
      state.chatList = action.payload;
      state.error = {};
    },
    [getAllChatList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single contact details
    [getSingleChat.pending]: (state) => {
      state.singleChatLoading = true;
    },
    [getSingleChat.fulfilled]: (state, action) => {
      state.singleChatLoading = false;
      state.chatView = action.payload;
      state.error = {};
    },
    [getSingleChat.rejected]: (state, action) => {
      state.singleChatLoading = false;
      state.error = action.error;
    },
  },
});

// export const { clearStaffError } = contactSlice.actions;

export default chatSlice.reducer;
