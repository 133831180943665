import PropType from 'prop-types'
import { m } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { Chip, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import VerifiedIcon from '@mui/icons-material/Verified';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import { colors } from 'src/theme/colors';

import { varHover } from 'src/components/animate';
import CustomPopover from 'src/components/custom-popover';
import TextTruncate from 'src/components/Typography/TextTruncate';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Home',
    linkTo: paths.dashboard.root,
  },
  {
    label: 'Profile',
    linkTo: `${paths.dashboard.profile}#general`,
  },
  {
    label: 'Settings',
    linkTo: `${paths.dashboard.profile}#security`,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover({ popover }) {
  const router = useRouter();
  const navigate = useNavigate();

  const { account } = useSelector((state) => ({
    account: state.auth.user,
  }));

  const { role } = useGetRoles();

  // console.log(account);

  // const popover = usePopover();

  const signOff = async () => {
    popover.onClose();
    navigate(paths.auth.logout);
  };

  const logout = () => {
    popover.onClose();
    navigate(paths.public.landing);
    sessionStorage.clear();
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 35,
          height: 35,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={account?.photoURL}
          alt={account?.name}
          sx={{
            width: 28,
            height: 28,
            fontSize: "12px",
            backgroundColor: colors.exLight,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {account?.name?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Stack sx={{ position: 'relative' }}>
          <Box sx={{ p: 2, pb: 1.5 }}>
            <Typography
              variant="subtitle2"
              noWrap
              sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center', mb: 1 }}
            >
              <TextTruncate text={account?.name} width={97} />
              <Chip sx={{ ml: 1 }} label={role} variant="outlined" color="warning" size="small" />
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {account?.user?.email}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {account?.phone}
            </Typography>
          </Box>
          {account?.is_guest && (
            <Chip
              sx={{ position: 'absolute', top: 10, right: 10 }}
              size="small"
              color="warning"
              label="Guest"
            />
          )}
          {account?.is_verified && (
            <Chip
              sx={{ position: 'absolute', top: 10, right: 10, fontSize: '12px' }}
              size="small"
              color="success"
              label="Verified"
              icon={<VerifiedIcon fontSize="small" />}
            />
          )}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack>
          <MenuItem
            sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
            onClick={logout}
          >
            Logout
          </MenuItem>
          <Tooltip title="Logout from all device">
            <MenuItem id="signOffId" onClick={signOff} sx={{ m: 1 }}>
              Sign Off
            </MenuItem>
          </Tooltip>
        </Stack>
      </CustomPopover>
    </>
  );
}

AccountPopover.propTypes = {
  popover: PropType.any
}