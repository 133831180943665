import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

export const MetaHelmet = ({ title }) => (
  <Helmet>
    <title> {title} | Chatosphere </title>
  </Helmet>
);

MetaHelmet.propTypes = {
  title: PropTypes.string,
};
