import * as React from 'react';
import PropTypes from 'prop-types';

import { Chip } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogContentText from '@mui/material/DialogContentText';

export default function DeleteConfirmDialog({ open, setOpen, onConfirmClick, item, title }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const deleteItem = item?.map((name, key) => (
    <React.Fragment key={key}>
      `<span>{name}</span>
      `
      <br />
    </React.Fragment>
  ));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{`Delete ${title || 'item'}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete &nbsp;{deleteItem}
            {` You can't undo this action.`}
          </DialogContentText>
          <Chip
            sx={{ mt: 2 }}
            color="warning"
            label={`By deleting this ${title || 'item'}, All the activity of this ${
              title || 'item'
            } will also be deleted.`}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={onConfirmClick} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteConfirmDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onConfirmClick: PropTypes.func,
  item: PropTypes.any,
  title: PropTypes.string,
};
