import React from 'react';
import PropTypes from 'prop-types';

import { alpha, styled } from '@mui/material/styles';
import { Card, Stack, Typography } from '@mui/material';

import Iconify from 'src/components/iconify';

// Styled component for the icon container
const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// Prop types for the AppWidgetSummary component
AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  todayCount: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
  subTitle1: PropTypes.string,
  subTitle2: PropTypes.string,
};

// Main AppWidgetSummary component
export default function AppWidgetSummary({
  title,
  todayCount,
  subTitle1,
  subTitle2,
  total,
  icon,
  color = 'primary',
  sx,
  ...other
}) {
  return (
    <Card
      // Card styles and custom styles using the `sx` prop
      sx={{
        minHeight: 262,
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      {/* Icon container */}
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        {/* Icon component */}
        <Iconify icon={icon} width={24} height={24} />
      </StyledIcon>

      {/* Render different content based on the `title` prop */}

      <Typography>{title}</Typography>
      <Stack direction="row" sx={{ justifyContent: 'center' }} mt={3}>
        <Stack mx={2}>
          <Typography>
            {subTitle1 || 'Total'}
            <Typography variant="h4">{total ?? 0}</Typography>
          </Typography>
        </Stack>
        <Stack mx={2}>
          <Typography>
            {subTitle2 || 'Today'}
            <Typography variant="h4">{todayCount ?? 0}</Typography>
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
