import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';


// ----------------------------------------------------------------------

export default function Terms({ isInner }) {
  return (
    <>
      <MetaHelmet title="Terms and Condition" />

      {/* <NotFoundView isInner /> */}
      <Typography variant='subtitle1' sx={{ mb: 5 }}>Chatosphere - Terms and Conditions</Typography>
      <Box sx={{ height: "100vh", textAlign: "start", }} className=''>
        <p style={{ fontWeight: "bold" }}>
          Last Updated June 14th, 2024
        </p>
        <p>
          Chatosphere is a product of Elevate Future.
        </p>
        <p>
          Elevate Future (“Elevate Future”, “Chatosphere”, “Chato sphere ”, “Chatosphere.io”, “we”, “us”, or “our”) provides Software as a Service (SaaS) that allows our customers to store, manipulate, analyze and transfer messages between their business systems and their customers on a variety of Chatosphere provided and third party messaging channels (the “Service”). A “Customer” is an entity with whom Chatosphere has an agreement to provide the Service.
        </p>
        <p>
          This Chatosphere Terms of Service (the “Agreement”) is an agreement between the Customer and Elevate Future (Chatosphere), together the “Parties” and each, a “Party”, and is entered on the date the Customer signs up for a Chatosphere account through the Chatosphere website (the “Effective Date”).
        </p>
        <p>
          If you register for a Chatosphere account, you acknowledge your understanding of these terms and enter the Agreement on behalf of the Customer. Please make sure you have the necessary authority to enter into the Agreement on behalf of the Customer before proceeding.
        </p>
        <p>
          If a Customer violates any of the terms outlined below, we reserve the right to cancel accounts or bar access to accounts without notice. If you do not agree to these terms, please do not use our Services.
        </p>
        <p>
          Chatosphere may, but have no obligation to, remove Content and Accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Use.
        </p>
        <p>
          Chatosphere may refuse service to anyone for any reason at any time,
        </p>
        <p>
          BY USING ANY ELEMENT OF THE Chatosphere SOLUTION (INCLUDING THE WEBSITE), CUSTOMER ACKNOWLEDGES THAT CUSTOMER HAS READ, ACCEPTS AND AGREES TO BE BOUND BY AND COMPLY WITH THE TERMS AND CONDITIONS, SET OUT IN THIS AGREEMENT, AS AMENDED FROM TIME TO TIME. IF CUSTOMER DOES NOT ACCEPT AND AGREE TO BE BOUND BY THIS AGREEMENT, CUSTOMER WILL IMMEDIATELY CEASE ANY FURTHER USE OF THE Chatosphere SOLUTION, INCLUDING USE OF ANY PART THEREOF. CUSTOMER REPRESENTS AND WARRANTS TO Chatosphere THAT CUSTOMER HAS THE CAPACITY TO ENTER INTO THIS LEGALLY BINDING AGREEMENT. IF CUSTOMER IS USING THE Chatosphere SOLUTION ON BEHALF OF ANOTHER PERSON, CUSTOMER HEREBY REPRESENTS AND WARRANTS TO Chatosphere THAT CUSTOMER HAS THE AUTHORITY TO BIND SUCH PERSON TO THIS AGREEMENT.
        </p>

        <p className="main-part">
          Chatosphere Platform
        </p>
        <p>
          Provisioning of the Chatosphere Platform. Subject to Customer’s compliance with the terms and conditions of this Agreement, Chatosphere will make the Chatosphere Platform available to Customer on the terms and conditions set out in this Agreement, provided that: (i) Customer has read and accepted and is in compliance with its obligations under the applicable terms of use related to all applicable Third Party Messaging Platforms, Pre-Integrated Third-Party Business Applications, and Custom-Integrated Business Applications; and (ii) has taken all steps as necessary to enable interoperability between the Chatosphere Platform and all applicable Third Party Messaging Platforms, Pre-Integrated Third-Party Business Applications, and Custom-Integrated Business Applications, including acquiring the necessary approvals and API keys, whether directly or via Customer’s Clients, to access these messaging channels as a business.
        </p>

        <p className="main-part">
          Modifications to the Service and Fees
        </p>
        <p>
          Chatosphere reserves the right to modify, suspend, or discontinue the Service at any time for any reason, with or without notice.
        </p>
        <p>
          Chatosphere reserves the right to change our monthly/annual fees upon 30 days’ notice. The fee change will be notified per email to all our subscribers and will be reflected on the pricing page at chatosphere.io/pricing.
        </p>
        <p>
          Chatosphere reserves the right to update and change the Terms of Service from time to time without notice. Any new features that augment or enhance the current Service, including the release of new tools and resources, shall be subject to the Terms of Service. Should you continue to use the Service after any such modifications have been made, this shall constitute your agreement to such modifications.
        </p>

        <p className="main-part">
          Trademark License
        </p>
        <p>
          During the Term, Customer hereby grants to Chatosphere a worldwide, non-exclusive, non-transferable and non-sub-licensable (other than to affiliates) royalty-free license to use Customer’s trademarks and logos made available to Chatosphere by Customer as part of this Agreement, solely in connection with the marketing, advertising, and promotion of the Chatosphere Solution, including listing the Customer and the Customer Application on the Chatosphere Website; and only in accordance with Customer’s reasonable trademark usage guidelines, as updated by Customer from time to time. Customer may require Chatosphere to cease using Customer’s trademarks and logos if, in the reasonable opinion of Customer, the continued display of Customer’s trademarks and logos would cause a materially adverse effect on Customer’s image and the goodwill associated therewith.
        </p>

        <p className="main-part">
          Copyrights &amp; Ownership
        </p>
        <p>
          Chatosphere expressly reserves all rights, title, and interest in, and Customer will not acquire any right, title or interest in (i) the Chatosphere Solution (or any part thereof) and any other materials or content provided by Chatosphere under this Agreement, including any and all Modifications to any of the foregoing; and (ii) all intellectual property rights in any of the foregoing (clauses (i) and (ii) are collective, the “Chatosphere Property”), in each case. All rights, titles and interests in the Chatosphere Property will remain with Chatosphere (or Chatosphere’s third-party suppliers, as applicable). Customers may not copy, modify, adapt, reproduce, distribute, reverse engineer, decompile, or disassemble any aspect of the Service which Chatosphere or its suppliers own. The customer agrees not to resell, duplicate, reproduce or exploit any part of the Service without the express written permission of Chatosphere. For greater certainty, the Chatosphere Property is licensed and not “sold” to the Customer.
        </p>
        <p>
          Chatosphere claims no intellectual property rights over the Content you upload or provide to the Service.
        </p>

        <p className="main-part-less">
          Chatosphere’s Right to Use Customer Data
        </p>
        <p>
          Customer acknowledges and agrees that Chatosphere may store, use, reproduce, Modify, and transfer to its subcontractors, Customer Data, including Personal Data, solely in connection with delivering the Services under this Agreement. Customer further acknowledges and agrees that Chatosphere may store, use, reproduce, Modify, and transfer data that is not related to an identified or identifiable natural person, including aggregated or de-identified data, without limitation, for its internal business purposes, including but not limited to such purposes as analytics, quality assurance, product and service improvement, and new product and service development. The customer agrees to cause any Administrative User, Customer’s Client and Chat Participant to agree to the terms.
        </p>

        <p className="main-part">
          Customer Responsibilities
        </p>
        <p>
          <span className="bold">Customer Accounts.</span> Upon Customer’s request, Chatosphere will issue one or more administrator accounts (the “Administrator Accounts”) to Customer that provides Customer with the capability to create accounts for use by individuals who are an employee or contractor of Customer and that Customer wishes to have access to and use of the Chatosphere Platform (each, an “Administrative User”). Customer should ensure that Administrative Users only use the Chatosphere Platform through their Customer Account. Customer should not share their Administrator Accounts with any other person and will not allow Administrative Users to share their Customer Account with any other person. The customer should promptly notify Chatosphere of any actual or suspected unauthorized use of the Chatosphere Platform. Chatosphere reserves the right to suspend, deactivate, or replace any Customer Account or Administrator Accounts if it determines that the Customer Account or Administrator Account, as applicable, may have been used for an unauthorized purpose.
        </p>
        <p>
          Customers are responsible for maintaining the privacy and security of their accounts. Chatosphere will not be held liable for any damage or loss that may result from your failure to protect your login information, including your password.
        </p>
        <p>
          <span className="bold">Use Restrictions.</span> Customer acknowledges and agrees that it is responsible for the activities and communications of all Administrative Users and Chat Participants on the Chatosphere Platform, and the compliance by all Administrative Users, Customer’s Clients and Chat Participants with this Agreement, and any guidelines and policies published by Chatosphere from time to time.
        </p>
        <p>
          The Customer is fully responsible for all activities that occur under the account and any other actions taken in connection with the account. You are responsible for all Content sent and activity that occurs under your account (even when Content is sent by others to your account).
        </p>
        <p>
          Chatosphere may, but have no obligation to, remove Content and Accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Use.
        </p>
        <p>
          Without limiting the generality of any of the foregoing, Customer will not, and will not permit any other person (including any Administrative Users, Customer’s Clients or Chat Participants or Contacts) to:
        </p>

        <p>
          – use the Chatosphere Platform to send, upload, collect, transmit, store, use, disclose or process, or ask Chatosphere to obtain from third parties or perform any of the above with respect to, any Customer Data:
        </p>

        <p className="one-sentence">
          that contains any computer viruses, worms, malicious code, or any software intended to damage or alter a computer system or data;
        </p>
        <p className="one-sentence">
          that Customer or the applicable Administrative User, Customer’s Client or Chat Participant does not have the lawful right to send, upload, collect, transmit, store, use, disclose, process, copy, transmit, distribute and display;
        </p><p className="one-sentence">
          that is false, intentionally misleading, or impersonates any other person;
        </p>
        <p className="one-sentence">
          that is bullying, harassing, abusive, threatening, vulgar, obscene, or offensive, or that contains pornography, nudity, or graphic or gratuitous violence, or that promotes violence, racism, discrimination, bigotry, hatred, or physical harm of any kind against any group or individual;
        </p>
        <p className="one-sentence">
          that is harmful to minors in any way or targeted at persons under the age of 16;
        </p>
        <p className="one-sentence">
          that violates any applicable laws, or infringes, violates or otherwise misappropriates the intellectual property or other rights of any third party (including any moral right, privacy right or right of publicity); or
        </p>
        <p className="one-sentence">
          that encourages any conduct that may violate, any applicable laws or would give rise to civil or criminal liability;
        </p>

        <p className="one-sentence">
          – attempt to gain unauthorized access to the Chatosphere Platform;
        </p>
        <p className="one-sentence">
          – use any data mining, robots, or similar data gathering or extraction methods, or copy, Modify, reverse engineer, reverse assemble, disassemble, or decompile the Chatosphere Solution or any part thereof or otherwise attempt to discover any source code, except as expressly provided for in this Agreement;
        </p>
        <p className="one-sentence">
          – use the Chatosphere Solution for the purpose of building a similar or competitive product or service; or
        </p>
        <p className="one-sentence">
          – use the Chatosphere Solution other than as permitted by this Agreement;
        </p>
        <p className="one-sentence">
          – that encourages any conduct that may violate, any applicable laws or would give rise to civil or criminal liability;
        </p>
        <p className="one-sentence">
          – attempt to gain unauthorized access to the Chatosphere Platform;
        </p>
        <p className="one-sentence">
          – use any data mining, robots, or similar data gathering or extraction methods, or copy, Modify, reverse engineer, reverse assemble, disassemble, or decompile the Chatosphere Solution or any part thereof or otherwise attempt to discover any source code, except as expressly provided for in this Agreement;
        </p>
        <p className="one-sentence">
          – use the Chatosphere Solution for the purpose of building a similar or competitive product or service; or
        </p>
        <p className="one-sentence">
          – use the Chatosphere Solution other than as permitted by this Agreement;
        </p>

        <p className="main-part">
          WhatsApp &amp; Usage Policy
        </p>
        <p className="one-sentence">
          – The Customer shall adhere to all the terms &amp; conditions set by WhatsApp at all times. WhatsApp may update WhatsApp Business Policy without notice; by continuing to use the WhatsApp Business Products after such change, the Customer consents to such changes.
        </p>
        <p className="one-sentence">
          https://www.whatsapp.com/legal/business-policy/
        </p>
        <p className="one-sentence">
          – The Customer is not in violation of the WhatsApp Commerce Policy and is not in any of the restricted industries. https://www.whatsapp.com/legal/commerce-policy/
        </p>
        <p className="one-sentence">
          – WhatsApp may add limits to businesses on the number of messages to send per day. All Customers must adhere to this Messaging Limit (https://developers.facebook.com/docs/whatsapp/api/rate-limits)
        </p>
        <p className="one-sentence">
          – WhatsApp has the absolute discretion to review, approve or reject any Message Templates (as defined in WhatsApp documentations) at any time.
        </p>
        <p className="one-sentence">
          – Customer agrees to ensure full compliance with WhatsApp Policies regarding sending of messages
        </p>
        <p className="one-sentence">
          – WhatsApp does not offer a way to be notified when a user has blocked your sender, or to retrieve a list of users who have blocked you.
        </p>
        <p className="one-sentence">
          – Any violation of these WhatsApp policies may lead to suspension of the number by WhatsApp. WhatsApp has absolute discretion to limit or remove Customer’s access to or use of the WhatsApp Business Products if Customer receives excessive negative feedback, causes harm to WhatsApp or WhatsApp’s users, or violates or encourages others to violate our terms or policies, as determined by WhatsApp in our sole discretion. If WhatsApp terminates your account for violations of relevant WhatsApp Business terms or policies, WhatsApp may prohibit Customer and Customer organization from all future use of WhatsApp products.
        </p>
        <p className="one-sentence">
          – Chatosphere shall take no responsibility in case of any such violations. Any additional charges arising due to this shall be borne by the Customer.
        </p>
        <p className="one-sentence">
          – Once registered on Chatosphere.io , Customers cannot reuse the WhatsApp number on WhatsApp Business App or WhatsApp mobile app. The Customer owns the phone number.
        </p>

        <p className="main-part">
          WhatsApp Onboarding &amp; Verification Terms
        </p>
        <p className="one-sentence">– WhatsApp imposes reviews, such as Facebook business verification (<a href="https://www.facebook.com/business/help/2058515294227817">https://www.facebook.com/business/help/2058515294227817</a>) to ensure the quality of businesses using WhatsApp products. These requirements are prerequisites to using WhatsApp Business API and Chatosphere. WhatsApp has absolute discretion to approve the Customer’s usage of WhatsApp products. Chatosphere will assist with the application and submission process.</p>
        <p className="one-sentence">– By using Chatosphere, clients agree to the setup for WABA and WhatsApp business API and will provide all the prerequisite documents as required by Whatsapp &amp; Facebook.</p>
        <p className="one-sentence">– WhatsApp may change acceptable message types and related policies at their absolute discretion at all times.</p>
        <p className="one-sentence">– The Customer may only initiate chats if you are sending a transactional notification, and only via approved Message Templates (as defined in WhatsApp documentation), subject to applicable pricing. Any Message Templates must comply with WhatsApp’s terms, and only be used for their designated purpose. WhatsApp has the right to review, approve and reject any Message Template at any time. — Customer acknowledges that they are responsible for the variable Message Template costs, billed by Chatosphere.</p>
        <p className="one-sentence">– If a person initiates a chat with a Customer, you may continue that conversation via WhatsApp for up to 24 hours after the last message sent to you by that person without charge (“Customer Service Window”). Outside of the Customer Service Window, Customer may only send messages via approved Message Templates, for which Chatosphere will bill and charge Customer the applicable rate.</p>
        <p className="one-sentence">– Customer may use automation when responding during the Customer Service Window, but must also have available prompt, clear, and direct escalation paths to human agents.</p>

        <p className="main-part">
          Email and Web Support
        </p>
        <p>
          Customer will generally have access to Chatosphere’s technical support from 10:00 am to 4:00 pm IST (GMT+5:30) on weekdays (other than holidays observed by Chatosphere) through email support, or through the website using our chat widget, support options or by mailing us on info@chatosphere.io
        </p>

        <p className="main-part">
          Fees and Payment
        </p>
        <p>
          The customer will pay Chatosphere the applicable fees described on the chatosphere.io Website (the “Fees”) during the Term in accordance with the payment terms set out herein.
        </p>

        <p className="main-part">
          Third-Party Messaging Platform Fees
        </p>
        <p>
          For greater clarity, Chatosphere’s Fees do not include any charges that may be assessed by Third Party Messaging Platforms for access to or use of a channel. Such charges shall be the responsibility of Customer, whether paid directly to the Third Party Messaging Platforms or whether such access is resold through Chatosphere, in which case Chatosphere shall advise Customer in writing as to the applicable charges and Customer shall have the right to accept such charges or decline them and not use the associated channel. In the event a Third Party Messaging Platforms imposes special requirements on Chatosphere beyond API integrations, including but not limited to hosting endpoints unique to that channel, then the Chatosphere shall have the right to charge Customer for this additional service, and Customer shall have the right to accept such charges or decline them and not use the associated channel.
        </p>

        <p className="main-part">
          Payment Terms:
        </p>
        <p className="one-sentence">– All Fees shall be in Indian Rupee (INR); Final fees may or may not be converted and shown in corresponding customer countries during payment.</p>
        <p className="one-sentence">– The Fees start immediately after you make the payment. The onboarding verification time is counted towards the subscription.</p>
        <p className="one-sentence">– One-time onboarding fee for WhatsApp Green Checkmark Verification is non-refundable. WhatsApp holds absolute discretion whether to grant Green Checkmark, that they verify Customer as an authentic, notable brand that owns the WhatsApp account. <a href="https://developers.facebook.com/docs/whatsapp/guides/display-name">https://developers.facebook.com/docs/whatsapp/guides/display-name</a></p>
        <p className="one-sentence">– Chatosphere shall charge and invoice the Customer applicable Fees in accordance with our pricing schedule, in advance, by credit card, on the Effective Date and on every anniversary thereafter. Subscription Fees are charged on a pre-pay basis. and usage Fees are charged on a post-pay basis at the end of each month and will cover the use of the variable messages fees for the previous month. All monthly &amp; usage payments are nonrefundable.</p>
        <p className="one-sentence">– The customer is responsible for all charges incurred (for example – international remittance, bank transfer, and handling fees), and Chatosphere shall receive the total amount stated in the invoice.</p>
        <p className="one-sentence">– There will be no refunds or credits for partial months of service, upgrade/downgrade refunds, or refunds for months unused with an open account. In order to treat everyone equally, no exceptions will be made.</p>
        <p className="one-sentence">– Payment obligations hereunder are not subject to any set-off or withholding rights whatsoever, any and all of which are hereby expressly waived by Customer.</p>

        <p>
          <span className="bold">– Disputed Invoices or Charges.</span> If Customer, in good faith, disputes any portion of a Chatosphere invoice or charge, Customer may provide a dispute notice to Chatosphere with written documentation identifying and substantiating the disputed amount within fifteen (15) days from receipt of the applicable invoice or charge, and if applicable, at the time it pays the undisputed portion of such invoice, withhold payment of such disputed portion. If Customer does not report or does not provide such substantiating documentation within that period, Customer shall be deemed to have waived its right to dispute any and all portions of that invoice.
        </p>
        <p>
          <span className="bold">– Late Payment.</span> Except for bona fide disputed amounts, any failure to make a payment of any Fees or taxes in strict conformity with the requirements entitles Chatosphere to suspend, in whole or in part, access to Services, until such payment is received. Additionally, Chatosphere shall assess, and Customer shall pay a charge, compounded monthly, of the lesser of (a) 1.5% per month (19.56% per year) or (b) the highest amount allowed by law on all past due amounts (except amounts disputed). Furthermore, upon any such failure, all outstanding amounts shall become due and payable without further delay.
        </p>
        <p className="one-sentence">– Any invoice overdue for more than 30 days will result in account suspension. The customer’s account will only be activated after all overdue invoices are fully settled.</p>
        <p className="one-sentence">– Any cancellation of your Account will result in the deactivation or deletion of your Account or your access to your Account, and the forfeiture and relinquishment of all Content in your Account. This information cannot be recovered from Chatosphere once your account is canceled.</p>
        <p className="one-sentence">– Taxes. The Fees set out in this Agreement do not include applicable taxes, duties, withholdings, tariffs, levies, customs, capital or income taxes or other governmental charges or expenses, including but not limited to value-added tax, sales tax, consumption tax and similar taxes or duties as well as any current or future municipal, state, federal or provincial taxes, and Customer will pay, indemnify and hold harmless Chatosphere from same, other than taxes based on the net income or profits of Chatosphere.</p>

        <p className="main-part">
          Limitation of Liabilities
        </p>
        <p>
          The Parties acknowledge that the following provisions have been negotiated by them and reflect a fair allocation of risk and form an essential basis of the bargain and will survive and continue in full force and effect despite any failure of consideration or of an exclusive remedy:
        </p>
        <p>
          AMOUNT. IN NO EVENT WILL THE TOTAL AGGREGATE LIABILITY OF Chatosphere IN CONNECTION WITH OR UNDER THIS AGREEMENT EXCEED THE AMOUNT OF FEES PAID BY CUSTOMER IN THE PRIOR 12 MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM OR 10000 INR, WHICHEVER IS THE LESSER. FOR GREATER CERTAINTY, THE EXISTENCE OF ONE OR MORE CLAIMS UNDER THIS AGREEMENT WILL NOT INCREASE THIS MAXIMUM LIABILITY AMOUNT. IN NO EVENT WILL Chatosphere’S THIRD PARTY SUPPLIERS HAVE ANY LIABILITY ARISING OUT OF OR IN ANY WAY CONNECTED TO THIS AGREEMENT.
          TYPE. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL Chatosphere BE LIABLE TO CUSTOMER OR ANY USER FOR ANY: (I) SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES, (II) LOST SAVINGS, PROFIT, DATA, USE, OR GOODWILL; (III) BUSINESS INTERRUPTION; (IV) ANY COSTS FOR THE PROCUREMENT OF SUBSTITUTE PRODUCTS OR SERVICES; (V) PERSONAL INJURY OR DEATH; OR (VI) PERSONAL OR PROPERTY DAMAGE ARISING OUT OF OR IN ANY WAY CONNECTED TO THIS AGREEMENT, REGARDLESS OF CAUSE OF ACTION OR THE THEORY OF LIABILITY, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE, GROSS NEGLIGENCE, FUNDAMENTAL BREACH, BREACH OF A FUNDAMENTAL TERM) OR OTHERWISE AND EVEN IF NOTIFIED IN ADVANCE OF THE POSSIBILITIES OF SUCH DAMAGES.
        </p>

        <p className="main-part">
          Term and Termination
        </p>
        <p>
          Chatosphere makes no warranties regarding (i) your ability to use the Service, (ii) your satisfaction with the Service, (iii) that the Service will be available at all times, uninterrupted, and error-free (iv), the accuracy of mathematical calculations performed by the Service, and (v) that bugs or errors in the Service will be corrected. Chatosphere and its affiliates are neither responsible nor liable for any direct, indirect, incidental, consequential, special, exemplary, punitive or other damages arising out of or relating in any way to your use of the Service. Your sole remedy for dissatisfaction with the Service is to stop using the Service.
        </p>
        <p>
          Term. This Agreement will commence on the Effective Date and continue to be in effect until your subscription has been terminated in accordance with the terms Customer agreed to on the Chatosphere website at the time of signup (the “Term”). For greater certainty, if the Customer has subscribed to an ongoing subscription, then the such subscription will automatically renew at its expiry for the same period of time, at the then-current subscription rate described on the Chatosphere Website.
        </p>

        <p className="main-part">
          Termination For Convenience
        </p>
        <p>
          Either Party may elect to terminate this Agreement and your subscription to Chatosphere services as of the end of your then-current Term by providing notice, on or prior to the date thirty (30) days preceding the end of such Term. For clarity, unless this Agreement and your subscription are so terminated, your subscription will renew for a Term equivalent in length to the then-expiring Term.
        </p>
        <p>
          The customer is responsible for properly canceling your Account. An email request to cancel your Account shall result in cancellation. Any cancellation of your Account will result in the deactivation or deletion of your Account or your access to your Account, and the forfeiture and relinquishment of all Content in your Account. This information cannot be recovered from Chatosphere once your account is canceled. After terminating a customer account, there will be a 90-day grace period where the customer can reactivate the account. After 90 days, the account deletion process will begin, and the customer will have to purchase a new account and cannot retrieve any of their existing Chatosphere account information. Please be aware of that. You can cancel at any time, but you will remain liable for all charges accrued up to that time, including full monthly charges for the month in which you discontinued service. You will not be charged again.
        </p>
        <p>
          Chatosphere will use all reasonable efforts to contact you directly via email to warn you prior to the suspension or termination of your account. Any suspected fraudulent, abusive, or illegal activity that may be grounds for termination of your use of Service may be referred to appropriate law enforcement authorities. Chatosphere shall not be liable to You or any third party for any modification, suspension or discontinuation of the Service.
        </p>

        <p className="main-part">
          Termination For Non-payment
        </p>
        <p>
          Chatosphere reserves the right to suspend Services if the Customer fails to timely pay any undisputed amounts due to Chatosphere under this Agreement, but only after Chatosphere notifies Customer of such failure and such failure continues for thirty (30) calendar days after such notification. Suspension of Services shall not release Customer of its payment obligations under this Agreement. Customer agrees that Chatosphere shall not be liable to Customer or to any third party for any liabilities, claims or expenses arising from or relating to suspension of the Services resulting from Customer’s non-payment unless Chatosphere suspends the Services without giving Customer 30 calendar days written notice to rectify its failure to pay.
        </p>
        <p>
          Termination will result in the deactivation or deletion of your Account or your access to your Account, and the forfeiture and relinquishment of all Content in your Account. This information cannot be recovered from Chatosphere once your account is terminated. Please be aware of that.
        </p>
        <p>
          <span className="bold">Refunds and Termination Charges.</span> No refunds or credits for Fees will be provided if you elect to terminate this Agreement prior to the end of your Term. If you terminate this Agreement prior to the end of your Term, or Chatosphere effects such termination, in addition to other amounts you may owe Chatosphere, you must immediately pay any then unpaid Fees associated with the remainder of your Term. This amount will not be payable by you in the event you terminate as a result of a material breach of this Agreement by Chatosphere, provided that you provide advance notice of such a breach to Chatosphere and afford Chatosphere not less than thirty (30) days to reasonably cure such breach.
        </p>
        <p style={{ paddingBottom: "100px" }}>
          <span className="bold">Termination for Cause.</span> A Party may terminate this Agreement for cause (a) upon thirty (30) days written notice to the other Party of a material breach if such breach remains uncured at the expiration of such period; or (b) if the other Party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors. If this Agreement is terminated by you in accordance with this section, Chatosphere will, to the extent permitted by applicable law, refund you any prepaid fees covering the remainder of the Term after the effective date of termination. If this Agreement is terminated by Chatosphere in accordance with this section, you will pay any unpaid fees covering the remainder of the Term. In no event will termination relieve you of your obligation to pay any fees payable to Chatosphere for the period prior to the effective date of termination.
        </p>
      </Box>
    </>
  );
}

Terms.propTypes = {
  isInner: PropTypes.bool,
};
