import * as Yup from 'yup';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, TextField, Autocomplete } from '@mui/material';

import { paths } from 'src/routes/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { registerApi } from 'src/server/api/auth';
import { countryCodes } from 'src/assets/data/countryCodes';

import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function ModernRegisterView() {
  const password = useBoolean();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const RegisterSchema = Yup.object().shape({
    first_name: Yup.string().required('First name required'),
    last_name: Yup.string(),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phone: Yup.string()
      .required('Phone required')
      .min(10, 'Phone must be at least 10 characters')
      .max(10, 'Phone must not exceed 10 characters'),
    country_code: Yup.string(),
    // .required('Country code is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  });

  const defaultValues = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    country_code: ''
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    // watch,
    // control
  } = methods;

  const [selectedCountryCode, setSelectedCountryCode] = useState('');


  const onSubmit = handleSubmit(async (data) => {
    console.log(data);

    const credentials = {
      state: {
        ...data,
        country_code: selectedCountryCode
      },
      dispatch,
      navigate,
    };
    dispatch(registerApi(credentials));
  });


  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
      <Typography variant="h4">Get started absolutely free</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> Already have an account? </Typography>

        <Link href={paths.login} component={RouterLink} variant="subtitle2">
          Sign in
        </Link>
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <>
      <Typography
        component="div"
        sx={{
          mt: 1,
          mb: 0,
          textAlign: 'center',
          color: 'text.secondary',
          fontSize: 11
        }}
        variant='caption'
      >
        Fields marked with an asterisk (*) are required and must be filled.
      </Typography>
      <Typography
        component="div"
        sx={{
          mt: 2.5,
          textAlign: 'center',
          typography: 'caption',
          color: 'text.secondary',
        }}
      >
        {'By signing up, I agree to '}
        <Link to={paths.public.terms} sx={{ cursor: "pointer" }} underline="always" color="text.primary">
          Terms of Service
        </Link>
        {' and '}
        <Link to={paths.public.privacy} sx={{ cursor: "pointer" }} underline="always" color="text.primary">
          Privacy Policy
        </Link>
        .
      </Typography>
    </>

  );


  const optionCountry = [
    ...(countryCodes || []).map((results) => ({
      label: `${results?.code} ${results?.name}`,
      // label: results?.code,
      value: results?.code,
    })),
  ];

  const handleAutocompleteChangeCountry = (e, newValue) => {
    setSelectedCountryCode(newValue?.value || '');
  };

  const handleTextFieldChangeCourse = (event) => {
    const searchText = event.target.value.trim().toLowerCase();
    const filteredOptions = countryCodes
      .filter((country) => country.name && country.name.trim().toLowerCase().includes(searchText))
      .map((result) => ({
        label: result.name,
        value: result.code,
      }));
    console.log(filteredOptions);
  };

  console.log(errors);

  const renderForm = (
    <Stack spacing={2.5}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <RHFTextField required name="first_name" label="First name" />
        <RHFTextField name="last_name" label="Last name" />
      </Stack>

      <RHFTextField required name="email" label="Email address" />

      <Stack sx={{ flexDirection: "row" }} spacing={2}>
        <Autocomplete
          id="country-select-demo"
          sx={{ width: '60%' }}
          options={optionCountry}
          autoHighlight
          size="small"
          // className="small-select"
          getOptionLabel={(option) => option.label}
          onChange={handleAutocompleteChangeCountry}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              name='country_code'
              placeholder="Select Country"
              InputLabelProps={{ shrink: true }}
              onChange={handleTextFieldChangeCourse}
              size="small"
              required
              inputProps={{
                ...params.inputProps,
                autoComplete: 'country_code',
              }}
            />
          )}
        />

        <RHFTextField required name="phone" label="Phone number" />
      </Stack>

      <RHFTextField
        name="password"
        label="Password"
        required
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <RHFTextField
        name="confirmPassword"
        label="Confirm New Password"
        required
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}

      />


      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
      >
        Create account
      </LoadingButton>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}

      {renderTerms}
    </FormProvider>
  );
}
