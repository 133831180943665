// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  public: {
    landing: '/landing',
    terms: `/terms`,
    privacy: `/privacy`,
  },
  // AUTH
  auth: {
    // login: `${ROOTS.AUTH}/login/admin`,
    login_member: `${ROOTS.AUTH}/login/member`,
    register: `${ROOTS.AUTH}/register`,
    logout: `${ROOTS.AUTH}/logout`,
    client_registration: `${ROOTS.AUTH}/client-registration`,

    create_password: (type, token) => `${ROOTS.AUTH}/create-password/:${type}/:${token}`,
    forgot_password_email: (type, token) => `${ROOTS.AUTH}/password/reset/:${type}/:${token}`,
    forgot_password: `${ROOTS.AUTH}/forgot-password`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    profile: `${ROOTS.DASHBOARD}/profile`,
    contact: `${ROOTS.DASHBOARD}/contacts`,
    chat: `${ROOTS.DASHBOARD}/chat`,
  },
};
