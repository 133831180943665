
import { useRef } from "react";
import PropTypes from "prop-types"
import InfiniteScroll from "react-infinite-scroll-component";

import Box from '@mui/material/Box';
import { CircularProgress } from "@mui/material";

import Lightbox, { useLightBox } from 'src/components/lightbox';

import ChatMessageItem from './chat-message-item';
// ----------------------------------------------------------------------

export default function ChatMessageList({ singleChatMessages, loading, handleFetchNextMsgPage }) {
  const scrollableDivRef = useRef(null);
  const slides = singleChatMessages
    ?.filter((message) => message.contentType === 'image')
    ?.map((message) => ({ src: message.body }));

  const lightbox = useLightBox(slides);

  return (
    <>
      <InfiniteScroll
        dataLength={singleChatMessages?.length || 0}
        next={handleFetchNextMsgPage}
        hasMore
        inverse
        endMessage={<p style={{ textAlign: 'center' }}><b>You have seen it all</b></p>}
        scrollableTarget={scrollableDivRef.current}
      >
        <div id="scrollableDivMessages" ref={scrollableDivRef} style={{ height: '520px', overflowY: 'auto', padding: "5px 20px" }}>
          <div style={{ textAlign: 'center', marginTop: '10px', display: loading ? 'block' : 'none' }}>
            <CircularProgress color='inherit' size={20} />
          </div>
          <Box>
            {singleChatMessages?.slice(0).reverse()?.map((message) => (
              <ChatMessageItem
                key={message._id}
                message={message}
                onOpenLightbox={() => lightbox.onOpen(message.body)}
              />
            ))}
          </Box>
        </div>
      </InfiniteScroll>

      <Lightbox
        index={lightbox.selected}
        slides={slides}
        open={lightbox.open}
        close={lightbox.onClose}
      />
    </>
  );
}

ChatMessageList.propTypes = {
  singleChatMessages: PropTypes.any,
  loading: PropTypes.bool,
  handleFetchNextMsgPage: PropTypes.func
};
