import * as xlsx from 'xlsx';
import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Stack, Dialog, DialogTitle, DialogContent } from '@mui/material';

import { createBulkContacts } from 'src/server/api/contact';

import { Upload } from 'src/components/upload';

ImportContactDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default function ImportContactDialog({ open, setOpen }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const { loading } = useSelector((state) => ({
    // labModelList: state.labModel.labModel,
  }));

  const [file, setFile] = useState(null);
  const [jsonData, setJsonData] = useState();

  console.log(jsonData);

  // useEffect(() => {
  //   if (!open) {
  //     setFile(null);
  //   }
  // }, [open]);

  const handleDropSingleFile = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);

    const newFile = acceptedFiles[0];
    if (newFile) {
      setFile(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet);
      setJsonData(json);
    };
    reader.readAsBinaryString(newFile); // Read the file as binary string
  }, []);

  const handleUpload = () => {
    const CreateCredentials = {
      state: {
        contacts: jsonData?.map((i) => ({ name: i.Name, whatsapp_number: i.Whatsapp_number })),
      },
      dispatch,
      handleClose,
    };
    dispatch(createBulkContacts(CreateCredentials));
  };

  const renderCommissionForm = (
    <Stack spacing={3} sx={{ p: 1, px: 0 }}>
      <Stack spacing={1.5}>
        <Upload
          file={file}
          type="file"
          onDrop={handleDropSingleFile}
          onDelete={() => setFile(null)}
        />
      </Stack>

      <Stack sx={{ marginBottom: '1rem' }} justifyContent="end" alignItems="center">
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          loading={loading}
          sx={{ color: 'white', ml: 'auto' }}
          color="success"
          onClick={handleUpload}
        >
          Import
        </LoadingButton>
      </Stack>
    </Stack>
  );
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Import File</DialogTitle>
        <DialogContent sx={{ minWidth: '450px' }}>{renderCommissionForm}</DialogContent>
      </Dialog>
    </div>
  );
}
