import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import AuthGuard from 'src/auth/guard/auth-guard';
import DashboardLayout from 'src/layouts/dashboard';
import AccountView from 'src/pages/Common/Auth/profile';
import DashboardAdminApp from 'src/pages/Common/Dashboard/DashboardAdminApp';
import DashboardMemberApp from 'src/pages/Common/Dashboard/DashboardMemberApp';

import { LoadingScreen } from 'src/components/loading-screen';

import { LandingView } from 'src/sections/landing/view';

import { paths } from './paths';
import { authRoutes, adminRoutes, publicRoutes, memberRoutes, } from './routes';

const Page404 = lazy(() => import('src/pages/Common/Static/404'));

// ----------------------------------------------------------------------

export default function Router() {
  const { role } = useGetRoles();
  console.log("role", role);

  const handleDashboardApp = () => {
    switch (role) {
      case 'admin':
        return <DashboardAdminApp />;
      case 'member':
        return <DashboardMemberApp />;
      default:
        return '';
    }
  };

  const mainRouteContainer = [
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: handleDashboardApp() },
        { path: 'profile', element: <AccountView /> },

        // other routes are listed here with their roles
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/dashboard/404" /> },
      ],
    },

    // handle notfound routes
    {
      path: '/landing',
      element: <LandingView />,
    },
    {
      path: '/',
      element: <Navigate to={paths.public.landing} replace />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const handleRouteByRoles = () => {
    switch (role) {
      case 'admin': {
        const modifiedRoute = mainRouteContainer?.map((route) =>
          route.path === '/dashboard'
            ? {
              ...route,
              children: [...route.children, ...adminRoutes],
            }
            : route
        );
        return modifiedRoute;
      }

      case 'member': {
        const modifiedRoute = mainRouteContainer?.map((route) =>
          route.path === '/dashboard'
            ? {
              ...route,
              children: [...route.children, ...memberRoutes],
            }
            : route
        );
        return modifiedRoute;
      }

      default:
        return mainRouteContainer;
    }
  };

  return useRoutes([
    {
      path: '/',
      element: <Navigate to={paths.public.landing} replace />,
    },

    ...handleRouteByRoles(),
    ...publicRoutes,
    ...authRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
