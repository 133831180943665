import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

// ----------------------------------------------------------------------

export default function ChatNavItem({ selected, setSelectedConversationId, collapse, conversation, onCloseMobile }) {
  const handleClickConversation = () => {
    onCloseMobile();
    setSelectedConversationId(conversation)
  }

  return (
    <ListItemButton
      disableGutters
      onClick={handleClickConversation}
      sx={{
        py: 1.5,
        px: 2.5,
        ...(selected && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <Badge
        color="error"
        overlap="circular"
        badgeContent={collapse ? conversation?.last_message
          ?.messages?.length
          : 0}
      >
        <Badge variant="standard" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Avatar src="" sx={{ width: 48, height: 48 }} />
        </Badge>
      </Badge>

      {!collapse && (
        <>
          <ListItemText
            sx={{ ml: 2 }}
            primary={conversation?.name}
            primaryTypographyProps={{
              noWrap: true,
              variant: 'subtitle2',
            }}
            secondary={conversation?.last_message?.text_message}
            secondaryTypographyProps={{
              noWrap: true,
              component: 'span',
              variant: 'body2',
              color: 'text.secondary',
            }}
          />

          <Stack alignItems="flex-end" sx={{ ml: 2, height: 44 }}>
            <Typography
              noWrap
              variant="body2"
              component="span"
              sx={{
                mb: 1.5,
                fontSize: 12,
                color: 'text.disabled',
              }}
            >
              {formatDistanceToNowStrict(new Date(conversation?.last_messaged_at), {
                addSuffix: false,
              })}
            </Typography>

            {/* {!!conversation?.unreadCount && ( */}
            <Box
              sx={{
                width: 8,
                height: 8,
                bgcolor: 'success.main',
                borderRadius: '50%',
              }}
            />
            {/* )} */}
          </Stack>
        </>
      )}
    </ListItemButton>
  );
}

ChatNavItem.propTypes = {
  collapse: PropTypes.bool,
  conversation: PropTypes.object,
  onCloseMobile: PropTypes.func,
  selected: PropTypes.bool,
  setSelectedConversationId: PropTypes.func,
};
