import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import SeoIllustration from 'src/assets/illustrations/seo-illustration';

import AppWelcome from '../admin/app-welcome';
import FacebookLoginButton from './FacebookLoginButton';

// ----------------------------------------------------------------------

const SPACING = 2;

export default function OverviewBookingView() {
  return (
    <Grid container spacing={SPACING} disableEqualOverflow>
      <Grid xs={12} md={8} sx={{ padding: 0 }}>
        <AppWelcome
          title="You're almost there!"
          description={
            <>
              {`Just one more step to finish your profile and you'll be ready to revolutionize your
              communication strategy with`}{' '}
              <Typography variant="caption" sx={{ fontWeight: 700 }}>
                Chatosphere
              </Typography>
              .
            </>
          }
          img={<SeoIllustration hideBackground />}
          action={
            <FacebookLoginButton />
            // <Button variant="contained" color="primary">
            //   Connect your account with Facebook
            // </Button>
          }
        />
      </Grid>
    </Grid>
  );
}
