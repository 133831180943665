import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { List, ListItem, Typography } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { colors } from 'src/theme/colors';

import Logo from 'src/components/logo';
import Image from 'src/components/image';
import IconsX from 'src/components/iconx';

import LoginImage from "../../assets/landing/illustration.png"

// ----------------------------------------------------------------------

export default function AuthModernLayout({ children, image, type }) {
  const mdUp = useResponsive('up', 'md');

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
      }}
    >
      <Logo
        sx={{
          mt: { xs: 8, md: 8 },
          mb: { xs: 10, md: 8 },
        }}
        overwrite
      />

      <Card
        sx={{
          py: { xs: 5, md: 0 },
          px: { xs: 3, md: 0 },
          boxShadow: { md: 'none' },
          overflow: { md: 'unset' },
          bgcolor: { md: 'background.default' },
        }}
      >
        {children}
      </Card>
    </Stack>
  );
  const renderSection = (
    <Stack flexGrow={1} sx={{ position: 'relative', backgroundColor: "#F9FAFB", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Typography variant='h4' sx={{ color: colors.primary }}>
        Innovative Chatosphere Solutions <br /> for Expanding Your Business
      </Typography>

      <Stack direction="row" mt={5} alignItems="start" justifyContent="space-between">
        <List>
          <ListItem color="#000">
            <IconsX icon="heroicons-outline:chat" bgColor="#6FC17F" />
            <Typography sx={{ color: colors.primary }}>
              Real-time Messaging
            </Typography>
          </ListItem>
          <ListItem >
            <IconsX icon="fluent:bot-sparkle-24-regular" bgColor="#6FC17F" />
            <Typography sx={{ color: colors.primary }}>

              Intelligent Bots
            </Typography>
          </ListItem>
          <ListItem>
            <IconsX icon="fluent:chat-arrow-double-back-20-regular" bgColor="#6FC17F" />
            <Typography sx={{ color: colors.primary }}>

              Replay Material
            </Typography>
          </ListItem>
          <ListItem>
            <IconsX icon="material-symbols-light:match-word-rounded" bgColor="#6FC17F" />
            <Typography sx={{ color: colors.primary }}>

              Keyword Triggers
            </Typography>
          </ListItem>
          <ListItem>
            <IconsX icon="ri:broadcast-line" bgColor="#6FC17F" />
            <Typography sx={{ color: colors.primary }}>

              Broadcasts Messaging
            </Typography>
          </ListItem>
          <ListItem>
            <IconsX icon="fluent:tag-multiple-20-regular" bgColor="#6FC17F" />
            <Typography sx={{ color: colors.primary }}>

              Categorization Tools
            </Typography>
          </ListItem>
        </List>
        <Image src={LoginImage} sx={{ height: "250px" }} />
      </Stack>
    </Stack>
  );

  const renderSectionMini = (
    <Stack flexGrow={1} sx={{ position: 'relative', backgroundColor: "#F9FAFB", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Typography variant='h4' sx={{ color: colors.primary }}>
        Innovative Chatosphere Solutions <br /> for Expanding Your Business
      </Typography>

      <Stack direction="row" mt={5} alignItems="start" justifyContent="space-between" >
        <List sx={{ width: "70%", margin: "auto" }}>
          <ListItem color="#000">
            <DoneAllIcon color='success' fontSize='small' sx={{ mx: 1 }} />
            <Typography sx={{ color: colors.primary }} variant='body2'>
              Launch targeted campaigns designed to deliver personalized offers to each customer, increasing engagement and satisfaction.
            </Typography>
          </ListItem>
          <ListItem color="#000">
            <DoneAllIcon color='success' fontSize='small' sx={{ mx: 1 }} />
            <Typography sx={{ color: colors.primary }} variant='body2'>
              Utilize pre-built templates and bots to streamline your operations and ensure consistent, high-quality customer interactions.
            </Typography>
          </ListItem>
          <ListItem color="#000">
            <DoneAllIcon color='success' fontSize='small' sx={{ mx: 1 }} />
            <Typography sx={{ color: colors.primary }} variant='body2'>
              Implement powerful automations that help resolve issues quickly, enhancing efficiency and customer satisfaction.
            </Typography>
          </ListItem>
          <ListItem color="#000">
            <DoneAllIcon color='success' fontSize='small' sx={{ mx: 1 }} />
            <Typography sx={{ color: colors.primary }} variant='body2'>
              Our intelligent bots can efficiently handle customer queries and provide accurate responses.
            </Typography>
          </ListItem>

          <ListItem>
            <DoneAllIcon color='success' fontSize='small' sx={{ mx: 1 }} />
            <Typography sx={{ color: colors.primary }} variant='body2'>
              Access replay materials to review and analyze past interactions for better insights.
            </Typography>
          </ListItem>

          <ListItem>
            <DoneAllIcon color='success' fontSize='small' sx={{ mx: 1 }} />
            <Typography sx={{ color: colors.primary }} variant='body2'>
              Set up keyword triggers to automatically respond to specific phrases, improving response times.
            </Typography>
          </ListItem>
          <ListItem>
            <DoneAllIcon color='success' fontSize='small' sx={{ mx: 1 }} />
            <Typography sx={{ color: colors.primary }} variant='body2'>
              Send broadcast messages to deliver important updates and announcements to a wide audience.
            </Typography>
          </ListItem>
          <ListItem>
            <DoneAllIcon color='success' fontSize='small' sx={{ mx: 1 }} />
            <Typography sx={{ color: colors.primary }} variant='body2'>
              Use categorization tools to organize and classify interactions for easier management and analysis.
            </Typography>
          </ListItem>
        </List>
      </Stack>

    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
        position: 'relative',
        '&:before': {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          position: 'absolute',
          backgroundSize: 'cover',
          opacity: { xs: 0.24, md: 0 },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: 'url(/assets/background/overlay_4.jpg)',
        },
      }}
    >

      {renderContent}

      {mdUp && (type === 'mini' ? renderSectionMini : renderSection)}
    </Stack>
  );
}

AuthModernLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  type: PropTypes.string,
};
