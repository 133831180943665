import { useState } from 'react';
import { useSelector } from 'react-redux';

// @mui
import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';
import DashboardLoading from 'src/components/loading/DashboardLoading';

import MemberDashboardContent from 'src/sections/app/member';

// components

// action

// sections

// ----------------------------------------------------------------------

export default function DashboardMemberApp() {
  // const dispatch = useDispatch();
  // const { enqueueSnackbar } = useSnackbar();
  const [selectedYear, setSelectedYear] = useState('2023');
  const { dashLoading } = useSelector((state) => ({
    dashLoading: state.dashboard.loading,
  }));

  const settings = useSettingsContext();

  return (
    <>
      <MetaHelmet title="Dashboard" />

      <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ mt: 4 }}>
        {!dashLoading ? (
          <MemberDashboardContent setSelectedYear={setSelectedYear} selectedYear={selectedYear} />
        ) : (
          <DashboardLoading />
        )}
      </Container>
    </>
  );
}
